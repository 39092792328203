export const assetsTab = [
  "Watchlist",
  "Private Stock",
  // "music",
  "sba7",
  "Manu. Home Loans",
  // "stocks",
  // "forex",
  // "commodities",
  // "marketIndexes",
  //crypto
];

export const searchTab = [
  "All",
  "Name",
  "Symbol",
  "Issuer",
];

export const SBA7A = "SBA(7a)";

export const assetTabs: any = {
  WATCHLIST: "watchlist",
  STOCKS: "stocks",
  MUSIC: "music",
  CRYPTO: "crypto",
  PRIVATES: "Private Stock",
  COMMODITIES: "commodities",
  FOREX: "forex",
  MARKETINDEXES: "marketIndexes",
  SBA7: "sba7",
  MHL: "Manu. Home Loans",
};

export const setterMapping: any = {
  Watchlist: "setWatchList",
  stocks: "setStocks",
  sba7: "setSba",
  // music: "setMusics",
  forex: "setForex",
  privates: "setPrivatesState",
  commodity: "setCommoditiesState",
};
export const assetTabsInclude: any = {
  WATCHLIST_QUERY: "watchlist",
  STOCKS_QUERY: "stocks",
  // MUSIC_QUERY: "music",
  CRYPTO_QUERY: "crypto",
  PRIVATES_QUERY: "privates",
  COMMODITIES_QUERY: "commodities",
  FOREX_QUERY: "forex",
  MARKETINDEXES_QUERY: "marketIndexes",
  SBA7_QUERY: "sba7",
};

export const navigation = ["Chart", "Order books", "Trades"];

export const colorList = {
  flickerGreen: "#58BD7D",
  flickerRed: "#FF6838",
  flickerWhite: "#ffffff",
  flickerBlack: "#17181b",
};

export const assestType: { [key: string]: string } = {
  stocks: "stock",
  crypto: "crypto",
  commodities: "commodity",
  privates: "privates",
  forex: "forex",
  marketIndexes: "marketIndex",
  // music: "music",
  SBA7: "sba7",
  MHL: "manufactured_home_loans"
};
export const actions = { BUY: "buy", SELL: "sell" };

export const defaultActiveCurrency = {
  id: "",
  name: "",
  symbol: "",
  marketPrice: 0,
  change: 0,
  changesPercentage: 0,
  image: "",
  imageFrom: "",
  imageTo: "",
  perDay: [],
  type: "Watchlist",
  address: "",
  lender_loan_number: "",
  isuerDetails: {}
};

export const LOAN_ASSETS: any = {
  manufactured_home_loans: true,
  sba7: true
}

export const ACTIVE_ASSET_TAB_WITH_SPACE: any = {
  privates: "Private Stock",
  manufactured_home_loans: "Manu. Home Loans",
}

export const ORDERBOOK_SERIES = "ORDERBOOK_SERIES";
export const gov_guaranteed_loan = "current_guaranteed_loan_balance";
