/* eslint-disable @typescript-eslint/no-use-before-define */
import React, { useCallback, useEffect, useMemo } from "react";
import { ReactModal } from "..";
import { Confirmation } from "@storybook";
import { useWehAuthn } from "@hooks/webAuthn";
import {
  useRecoilState,
  useRecoilValue,
  useResetRecoilState,
} from "recoil";
import {
  ApprovalStatusState,
  OptionsResponseState,
} from "@views/SignIn/stores";
import { userPersonalDetails } from "@states/user";
import { WaitingForApproval } from "@views/exchange/components/waiting-for-approval";
import { Nosupported } from "@components/no-supported";
import { ALLOW_MPC_ENV } from "../../envs";

type Props = {
  onSuccess: () => void;
  onFailure: () => void;
  render?: any;
};

export const DeviceBiometrics = (props: Props) => {
  const { onSuccess, onFailure, render } = props;
  const resetOptionResponseState = useResetRecoilState(OptionsResponseState);
  const { phone, countryCode } = useRecoilValue(userPersonalDetails);
  const [verficationStatus, setVerficationStatus] =
    useRecoilState(ApprovalStatusState);
  const optionsResponse = useRecoilValue(OptionsResponseState);

  const handleWebAuthnSuccess = useCallback(() => {
    resetOptionResponseState();
    setVerficationStatus("");
    !!render && render(false, false);
    onSuccess();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

  const handleWebAuthnFailure = useCallback(() => {
    resetOptionResponseState();
    setVerficationStatus("");
    !!render && render(false, false);
    onFailure();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const {
    registerNewCredential,
    handleWebAuth,
    isbiometricLoading,
    isDeviceRegisterd,
    setIsDeviceRegistered,
    startRegisterDevice,
    biometricWaitingForApproval,
    setBiometricWaitingForApproval,
    authenticationLoading,
    setAuthenticationLoading,
    handleRegistrastionSuccess,
    isWebAuthSuppoerted,
    setIsWebAuthSupported
  } = useWehAuthn(handleWebAuthnSuccess, handleWebAuthnFailure);

  const handleWebAuthentication = useCallback( async () => {
    await handleWebAuth();
    !!render && render(isbiometricLoading, true);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isbiometricLoading])

  useEffect(() => {
      handleWebAuthentication();
  }, []);

  useEffect(() => {
    if (
      verficationStatus === "approved" &&
      optionsResponse?.registrationOptions
    ) {
      registerNewCredential(
        handleRegistrastionSuccess,
        optionsResponse?.registrationOptions ?? {}
      );
    }
    // eslint-disable-next-line
  }, [optionsResponse]);

  const handleBiometricModalClose = useCallback(() => {
    resetOptionResponseState();
    setVerficationStatus("");
    setIsDeviceRegistered(true);
    !!render && render(false, false);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])


  const handalConfimation = useCallback(
    async () => {
      await startRegisterDevice();
      setAuthenticationLoading(false);
      setIsDeviceRegistered(true);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [phone, countryCode, isDeviceRegisterd]
  );

  const onWaitingForApprovalSuccess = (value: string) => {
    setBiometricWaitingForApproval(false);
    startRegisterDevice(value);
  };

  const renderDeviceBiometricsConfirmationModal = useMemo(() => {
    return (
      <ReactModal
        key="deviceBiometricsScreen"
        visible={true}
        onClose={handleBiometricModalClose}
      >
        <Confirmation
          title={"Look like your browser is not registered for webAuthn!"}
          visible={true}
          description={
            <>Please register this browser for WebAuthn before using device biometric login.
              {!!ALLOW_MPC_ENV && <span className="yellow-anchor" onClick={onSuccess}>Skip for now</span>}</>
          }
          handleModal={handalConfimation}
          handleClose={handleBiometricModalClose}
          boldDescription=""
          label="Register for webAuthn"
          cancelLabel="Close"
          type="buy"
          cancelLoading={authenticationLoading}
          confirmationHeaderStyle={{ borderRadius: "20px" }}
        />
      </ReactModal>
    );
  }, [handleBiometricModalClose, authenticationLoading, handalConfimation]);

  const renderNotSupportedModal = useMemo(() => {
    return (
      <ReactModal
        visible={!isWebAuthSuppoerted}
        onClose={() => setIsWebAuthSupported(true)}
      >
        <Nosupported
          title={"Look like your browser doesn’t support WebAuthn"}
          visible={!isWebAuthSuppoerted}
          description={
            "Your browser does not support WebAuthn. Please try other available method."
          }
          handleModal={() => setIsWebAuthSupported(true)}
          boldDescription=""
          label={"Close"}
          type="Secondary"
        />
      </ReactModal>
    );
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isWebAuthSuppoerted]);
  
  return (
    <>
      {!isDeviceRegisterd && renderDeviceBiometricsConfirmationModal}
      {biometricWaitingForApproval && (
        <WaitingForApproval
          key="waitingScreen"
          onSuccess={(value: string) => onWaitingForApprovalSuccess(value)}
          onFailure={handleWebAuthnFailure}
        />
      )}
      {!isWebAuthSuppoerted && renderNotSupportedModal}
    </>
  );
};
