import { Image } from "@storybook";
import "./no-data.scss";

interface INoData {
  img: string;
  description: string;
}

/**
 * NoData component to display when there is no data available.
 *
 * @param {INoData} props - The properties for the NoData component.
 * @param {string} props.img - The URL of the image to display.
 * @param {string} props.description - The description message to show.
 * @returns {JSX.Element} The NoData component.
 */
export const NoData = ({ img, description }: INoData) => {
  return (
    <div className="no-data-wrapper">
      <div className="no-data-wrapper__inner">
        <Image fileName={img} className="no-data-wrapper__image" />
        <div className="no-data-wrapper__description">{description}</div>
      </div>
    </div>
  );
};
