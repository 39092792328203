import react from "react";
import numeral from "numeral";
import { useRecoilValue } from "recoil";
import BigNumber from "bignumber.js";
import { userSelectedCurrencyState } from "states";

export const useCurrency = () => {
  const currency = useRecoilValue(userSelectedCurrencyState);
  const {
    symbolOnLeft,
    spaceBetweenAmountAndSymbol,
    symbol,
    rate = 0,
    code: currencyCode,
  } = currency ?? {};

  const formatCurrency = (value: string | number, decimal: number) => {
    if (value == null) {
      return "0.00";
    }
    const bigValue = new BigNumber(value);
    const bigRate = new BigNumber(currency.rate);
    const convertedValue = bigValue.multipliedBy(bigRate);
    const isNegative = convertedValue.isNegative();
    const absoluteValue = convertedValue.abs();
  
    // Format the number with the specified decimal places
    const numValue = absoluteValue.toFormat(decimal);
  
    if (currency.symbolOnLeft) {
      if (currency.spaceBetweenAmountAndSymbol) {
        return `${isNegative ? "-" : ""}${currency.symbol} ${numValue}`;
      }
      return `${isNegative ? "-" : ""}${currency.symbol}${numValue}`;
    } else {
      if (currency.spaceBetweenAmountAndSymbol) {
        return `${isNegative ? "-" : ""}${numValue} ${currency.symbol}`;
      }
      return `${isNegative ? "-" : ""}${numValue}${currency.symbol}`;
    }
  };
  
  
  const getBillion = (value: number | string, decimal: number) => {
    // const decimalTLength = value?.toString()?.split(".")[1]?.length ?? decimal;
    // const decimalPlaces = decimalTLength > decimal ? decimal : decimalTLength;

    const format = () => {
      const decimalLength = new Array(decimal ?? 0).fill("0").join("");
      return `0,0.${decimalLength}`;
    };

    if (isNaN(Number(value))) return numeral(Number("0.00")).format(format());
    else if (Math.abs(Number(value)) >= 1.0e12)
      return numeral(Math.abs(Number(value)) / 1.0e12).format(format()) + "T";
    else if (Math.abs(Number(value)) >= 1.0e9)
      return numeral(Math.abs(Number(value)) / 1.0e9).format(format()) + "B";
    else if (Math.abs(Number(value)) >= 1.0e6)
      return numeral(Math.abs(Number(value)) / 1.0e6).format(format()) + "M";
    else if (Math.abs(Number(value)) >= 1.0e3)
      return numeral(Math.abs(Number(value)) / 1.0e3).format(format()) + "K";
    else return numeral(Number(value)).format(format());
  };

  const formatCurrencyWithBillion = (
    value: string | number,
    decimal: number,
    withCode?: boolean,
    withoutSymbol?: boolean
  ) => {
    if (value == null) {
      value = 0;
    }

    const convertedValue = Number(value) * Number(rate);
    const valueType = /-/gi.test(convertedValue.toString());
    const modifiedValue = valueType ? convertedValue.toString().split("-") : "";

    let numValue: string | number = !valueType
      ? convertedValue ?? 0
      : modifiedValue?.[1] ?? 0;

    numValue = getBillion(numValue, decimal);

    if(withoutSymbol){
      return `${valueType ? "-" : ""}${numValue} ${withCode ? currencyCode : ""}`;
    }
    if (symbolOnLeft) {
      if (spaceBetweenAmountAndSymbol) {
        return `${valueType ? "-" : ""}${numValue} ${symbol} ${withCode ? currencyCode : ""}`;
      }

      return `${valueType ? "-" : ""}${symbol}${numValue} ${withCode ? currencyCode : ""}`;
    } else {
      if (spaceBetweenAmountAndSymbol) {
        return `${valueType ? "-" : ""}${numValue} ${symbol} ${withCode ? currencyCode : ""}`;
      }
      return `${valueType ? "-" : ""}${numValue}${symbol} ${withCode ? currencyCode : ""}`;
    }
  };
  const formatNumberWithBillion = (value: string | number, decimal: number) => {
    if (value == null) {
      return "0.00";
    }

    const convertedValue = Number(value) * Number(rate);
    const valueType = /-/gi.test(convertedValue.toString());
    const modifiedValue = valueType ? convertedValue.toString().split("-") : "";

    let numValue: string | number = !valueType
      ? convertedValue ?? 0
      : modifiedValue?.[1] ?? 0;

    numValue = getBillion(numValue, decimal);
    return numValue;
  };

  const formatCurrencyNumber = (
    value: string | number,
    decimal: number | boolean
  ) => {
    if (value == null) {
      return "0.00";
    }

    const convertedValue = Number(value) * Number(rate);

    if (typeof decimal === "number") {
      // const decimalTLength = value?.toString()?.split(".")[1]?.length ?? 0;
      // const decimalPlaces = decimalTLength > decimal ? decimal : decimalTLength;
      const format = () => {
        const decimalLength = new Array(decimal ?? 2).fill("0").join("");
        return `0,0.${decimalLength}`;
      };
      const numValue = numeral(convertedValue ?? 0).format(format());
      return numValue;
    }
    return (
      numeral(convertedValue ?? 0).value()?.toString() ?? ""
    );
  };

  const addSymbolCurrency = (value: string | number, decimal: number) => {
    const format = () => {
      const decimalLength = new Array(decimal ?? 2).fill("0").join("");
      return `0,0.${decimalLength}`;
    };
    const numValue = numeral(value ?? 0).format(format());

    return `${symbol}${numValue}`;
  };

  const convertToUSD = (currentPrice: number | string) => {
    return (Number(currentPrice) / Number(rate)).toString();
  };

  const currencyConvertInNumber = (value: string | number) => {
    return Number(numeral(Number(value) * Number(rate) ?? 0).format("0.0000"));
  };

  const amountToBondPoints = (actualPrice: string | number, price: string | number, decimal: number = 6) => {
    if (price === null || actualPrice === null) return "0.00"
    actualPrice = Number(actualPrice);
    price = Number(price);
    let final = (((price * 100) / actualPrice) || 0);
    const decimalTLength = final?.toString()?.split(".")[1]?.length ?? 0;
    const decimalPlaces = decimalTLength > decimal ? decimal : decimalTLength;
    return parseFloat(final.toFixed(decimalPlaces));
  };

  const bondPointToAmount = (actualPrice: string | number, bondPoint: string | number) => {
    if (bondPoint === null || actualPrice === null) return 0
    actualPrice = Number(actualPrice);
    bondPoint = Number(bondPoint);
    const roundSmallValue = (val: number) => {
      return Number((val).toLocaleString('fullwide', {useGrouping:false}));
    }
    return roundSmallValue((actualPrice * (bondPoint / 100)) || 0)
  };

  return {
    formatCurrency,
    formatCurrencyNumber,
    addSymbolCurrency,
    currencyCode,
    convertToUSD,
    currencySymbol: symbol,
    currencyConvertInNumber,
    formatNumberWithBillion,
    formatCurrencyWithBillion,
    amountToBondPoints,
    bondPointToAmount,
  };
};
