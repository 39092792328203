import React from 'react'
import "./accountApprovalBanner.scss"

const AccountApprovalBanner = () => {
  return (
    <>
      <div className="account-approval-banner">
        <i className="ri-information-fill informationIcon" /> Hang Tight! Your
        account is under review and may take up to 2 business days for approval.
        We’ll notify you once you're all set!
      </div>
    </>
  );
}

export default AccountApprovalBanner