import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import cn from "classnames";

import {
  ActiveCurrencyState,
  ActiveAssetTab,
  activeTypeState,
  perDayState,
  AssetDetailsState, 
  userSelectedCurrencyState,
  IsOpenAssetLoadingState,
  AssetAllDetailsState,
  AssetLoaderState, userPersonalDetails,
} from "states";
import { convertToCurrencySystem, mergeStringWithSymbol, useFormatNumber } from "utils";
import {
  AddWatchlist,
  Icon, Loader, ReactModal,
  Skeleton,
  SkeletonHeader,
  SkeletonLogo,
} from "components";
import { useNetwork } from "hooks";
import { APIS } from "constant";
import { Price } from "../Price";
import { LOAN_NAME, items, itemsMusic, newWatchlistKey, ACCREDITATION_LABEL } from "./constants";
import { useCurrency } from "hooks/currency";

import styles from "./Main.module.sass";
import { Json } from "types";
import { assetTabs, assetTabsInclude, LOAN_ASSETS, ORDERBOOK_SERIES } from "@views/exchange/constants";
import UseChatroomApi from "@views/Chat/store/chatroomApi";
import { assestType } from "@views/exchange/constants";

export const Main = () => {
  //global state
  const activeCurrency = useRecoilValue(ActiveCurrencyState);
  const personalData = useRecoilValue(userPersonalDetails);
  const [assetDetails, setAssetDetails] = useRecoilState(AssetDetailsState);
  const setAssestAlldetails = useSetRecoilState(AssetAllDetailsState);
  const setChartPerDay = useSetRecoilState(perDayState);
  const [activeTab] = useRecoilState(ActiveAssetTab);
  const setType = useSetRecoilState(activeTypeState);
  const userSelectedCurrency = useRecoilValue(userSelectedCurrencyState);
  const isOpenAssetLoading = useRecoilValue(IsOpenAssetLoadingState);
  const setAssestLoaderState = useSetRecoilState(AssetLoaderState);
  const { getChatInitiated, chatInitiatedLoading } = UseChatroomApi();

  //custom hook
  const {
    get: getSummaries,
    data: summaries,
    loading: loadingSummaries,
    isLoaded: loadedSummaries,
  } = useNetwork();

  const {
    formatCurrency,
    amountToBondPoints,
    formatCurrencyNumber,
    formatCurrencyWithBillion,
  } = useCurrency();

  const {
    symbol,
    name,
    marketPrice,
    change,
    changesPercentage,
    image,
    imageFrom,
    imageTo,
    type: assetType,
    id: assetID,
    rawSymbol,
    amount,
    releaseDate,
    duration,
    previewUrls,
    artist,
    currentLoanBalance,
    currentYield,
    sbaType,
    loanType,
    issuerDetails,
    numberOfLoans,
    averageLoanBalance,
    accreditationType,
    lastTradedPrice,
  } = activeCurrency || {};

  const { issuerName } = issuerDetails || {}

  const {
    stocks,
    music,
    crypto,
    commodities,
    privates,
    forex,
    marketIndexes,
    sba7,
  } = newWatchlistKey ?? {};

  useEffect(() => {
    setAssestLoaderState({ IsLoading: true });
    if (symbol && assetType) {
      switch (assetType) {
        case music:
          setAssestLoaderState({ IsLoading: false });
          //   // getMusicSummaries(`${APIS.MusicSummaries}/${musicId}`);
          break;
        case stocks:
          getSummaries(`${APIS.StockSummaries}/${symbol}`);
          break;
        case crypto:
          getSummaries(`${APIS.CryptoSummaries}/${symbol}`);
          break;
        case commodities:
          getSummaries(`${APIS.CommoditySummaries}/${symbol}`);
          break;
        case forex:
          getSummaries(`${APIS.ForexSummaries}/${symbol}`);
          break;
        case marketIndexes:
          getSummaries(`${APIS.MarketIndexSummaries}/${symbol}`);
          break;
        case privates:
          getSummaries(`${APIS.PrivateSummaries}/${assetID}`);
          break;
      }
    }
  }, [
    symbol,
    assetType,
    setAssestLoaderState,
    stocks,
    getSummaries,
    crypto,
    commodities,
    forex,
    marketIndexes,
    privates,
    assetID,
    music,
  ]);

  useEffect(() => {
    setAssestLoaderState({ IsLoading: false });
    if (summaries && summaries.message === "ok") {
      setAssetDetails(summaries.data?.detail);
      setAssestAlldetails(summaries.data);
    }
    setAssestLoaderState({ IsLoading: false });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [summaries]);

  useEffect(() => {
    if (activeCurrency) {
      if (activeTab.key === "Watchlist" || activeTab.key === "") {
        setType(activeCurrency.type);
      } else {
        setType(
          activeTab.key === assetTabs.PRIVATES
            ? assetTabsInclude.PRIVATES_QUERY
            : activeTab.key
        );
      }
      /*if (activeCurrency.symbol === "") {
        setChartPerDay([]);
      }*/
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeCurrency]);

  const [isError, setIsError] = useState(false);
  useEffect(() => {
    setIsError(false);
  }, [activeCurrency]);

  const { NumberWithCommas } = useFormatNumber();
  const getLogo = useMemo(() => {
    if (assetType === forex) {
      if (imageFrom && imageTo && !isError) {
        return (
          <div className={styles.imagewrapper}>
            <div className={styles.imagefromcontiner}>
              <img
                className={styles.imagefrom}
                src={imageFrom}
                alt=""
                onError={() => setIsError(true)}
              />
            </div>
            <div className={styles.imagetocontiner}>
              <img
                className={styles.imageto}
                src={imageTo}
                alt=""
                onError={() => setIsError(true)}
              />
            </div>
          </div>
        );
      } else {
        return <div>{symbol?.charAt(0).toUpperCase() ?? "?"}</div>;
      }
    } else {
      return image && !isError ? (
        <img src={image} alt="" onError={() => setIsError(true)} />
      ) : (
        <div>
          {LOAN_ASSETS[assetType] || assetType === music
            ? name?.charAt(0).toUpperCase()
            : symbol?.charAt(0).toUpperCase() ?? "?"}
        </div>
      );
    }
  }, [
    assetType,
    forex,
    image,
    imageFrom,
    imageTo,
    isError,
    name,
    sba7,
    symbol,
  ]);

  const musicArtistLogo = useMemo(() => {
    return artist?.image_url ? (
      <img src={artist?.image_url} alt="" />
    ) : (
      <div> {artist?.name?.charAt(0).toUpperCase()}</div>
    );
  }, [artist?.image_url, artist?.name])
  
  const renderPerDayValue = useCallback(
    (key: string) => {
      const details: any = activeCurrency?.assetValuation; 
        // assetType === music ? activeCurrency : assetDetails;
      return Object.keys(details ?? {}).length > 0 && details?.[key] !== 0
        ? (key !== "volume" && key !== "tradedVolume")
          ? formatCurrency(details[key], 2)
          : NumberWithCommas(details[key])
        : "0.00";
    },
    [
      assetType,
      music,
      activeCurrency,
      assetDetails,
      formatCurrency,
      NumberWithCommas,
    ]
  );

  const isLoading = useMemo(
    () =>
      assetType !== music
        ? !loadingSummaries && loadedSummaries
        : activeCurrency?.musicId,
    [
      activeCurrency?.musicId,
      assetType,
      loadedSummaries,
      loadingSummaries,
      music,
    ]
  );

  //TODO: usage not clear, so commenting out the code. We were not using this anywhere
  // const timeFormate = useCallback((time: string) => {
  //   const timeArr = time.split(":");
  //   return `${AddZeroInSingleNumbers(timeArr[0] ?? 0)}:${timeArr[1] ?? "00"}`;
  // }, []);

  //TODO: can be a util fn
  function formatDuration(durationStr: string) {
    const duration = parseInt(durationStr) ?? 0;
    const seconds = Math.floor((duration / 1000) % 60);
    const minutes = Math.floor((duration / (1000 * 60)) % 60);
    const hours = Math.floor((duration / (1000 * 60 * 60)) % 24);

    const hoursDisplay =
      hours > 0 ? hours.toString().padStart(2, "0") + ":" : "";
    const minutesDisplay = minutes.toString().padStart(2, "0");
    const secondsDisplay = seconds.toString().padStart(2, "0");

    return hoursDisplay + minutesDisplay + ":" + secondsDisplay;
  }

  const renderPerDay = useMemo(() => {
    const allowItemMusic: Json = { music, stock: stocks };
    return (allowItemMusic[assetType] ? itemsMusic : items).map(
      (item: { key: string; icon: string; title: string }, index) => {
        return (
          <div className={styles.item} key={index}>
            <div className={styles.subtitle}>
              <Icon name={item.icon} size="16" />
              {item.title}
            </div>
            <div
              className={styles.content}
              style={{
                color:
                  item.key === "open" || item.key === "openPrice"
                    ? assetDetails?.change >= 0
                      ? "#58BD7D"
                      : "#FF6838"
                    : "unset",
              }}
            >
              {isLoading ? renderPerDayValue(item.key) : <Skeleton />}
            </div>
          </div>
        );
      }
    );
  }, [assetDetails?.change, assetType, isLoading, music, renderPerDayValue]);

  function capitalizeFirstLetter(value: string) {
    return value.charAt(0).toUpperCase() + value.slice(1).toLowerCase();
  }

  const handleChatClick = useCallback(async () => {
    if (chatInitiatedLoading) return;
    getChatInitiated({
      assetId: activeCurrency?.id || "",
      receiverId: activeCurrency?.customerId || "",
      senderId: personalData?.customerId || "",
    })
  }, [activeCurrency, personalData, chatInitiatedLoading]);

  const assetInfo = mergeStringWithSymbol(issuerName, symbol, ".") ?? "--";
  const assetStockName = mergeStringWithSymbol(issuerName, name, " ");
  const isOrderBook = sbaType === ORDERBOOK_SERIES;
  return (
    <div
      className={`${styles.main} ${assetType === music ? styles.music : ""} `}
    >
      {LOAN_ASSETS[assetType] ? (
        <>
          <div className={cn(styles.details, styles.sbaDetails)}>
            <div className={styles.box}>
              {symbol === "" ? (
                <div className={styles.line}>
                  <div className={styles.img}>
                    <SkeletonLogo />
                  </div>
                  <div className={styles.skeleton_asset}>
                    <SkeletonHeader />
                  </div>
                </div>
              ) : (
                <div className={styles.sbaLine}>
                  <div
                    className={`${styles.img} ${
                      assetType === music ? styles.music_img : ""
                    }`}
                  >
                    {getLogo}
                    {!!ACCREDITATION_LABEL[accreditationType || ""] &&
                      <img className={styles.accreditationLabel} src={`/media/icon/${accreditationType}.svg`} alt="Accreditation" />}
                  </div>
                  <div>
                    <div data-tooltip-id={assetInfo} className={styles.info}>
                      {assetInfo}
                    </div>
                    <div className={styles.stock_name} data-tooltip-id={assetStockName}>{assetStockName}</div>
                  </div>
                </div>
              )}
              {!personalData?.isBlockChat &&
                !!activeCurrency?.customerId &&
                activeCurrency?.customerId !== personalData?.customerId && (
                  <div>
                    <button
                      className={styles.chat_btn}
                      onClick={handleChatClick}
                      disabled={chatInitiatedLoading}
                    >
                      {chatInitiatedLoading ? (
                        <Loader dimension={20} />
                      ) : (
                        <>
                          <i className="ri-chat-voice-fill"></i>Chat
                        </>
                      )}
                    </button>
                  </div>
                )}
            </div>
            <div className={cn(styles.sbacolumn)}>
              <div className={styles.box}>
                {/* {marketPrice !== 0 ? ( */}
                 {sbaType=== "ORDERBOOK_SERIES" ? <><div className={styles.subtitlesba}>
                 Number of Loans
                  </div>
                  <div className={styles.sbacontent}>
                    {numberOfLoans}
                  </div></> : <><div className={styles.subtitlesba}>
                      Price in Bond Points
                    </div>
                    <div className={styles.sbacontent}>
                    {Number(convertToCurrencySystem(
                          amountToBondPoints(currentLoanBalance, marketPrice),
                          4
                    )).toFixed(6)}
                  </div></> } 
                {/* ) : (
                  <SkeletonHeader />
                )} */}
              </div>
              <div className={styles.box}>
                {userSelectedCurrency?.code &&
                  !isOpenAssetLoading &&
                      // marketPrice !== 0 ? (

                  <>{sbaType=== "ORDERBOOK_SERIES" ? <>  <div className={styles.subtitlesba}>Average Loan Balance</div>
                            <div className={styles.content}>
                              {formatCurrencyNumber(averageLoanBalance, 2)}
                  </div></> : <>  <div className={styles.subtitlesba}>Price (USD)</div>
                            <div className={styles.content}>
                              {formatCurrencyNumber(marketPrice, 2)}
                  </div></>}
                  
                      </>

                  // ) : (
                  //   <SkeletonHeader />
                  // )
                }
              </div>
              <div className={styles.box}>
                {currentYield ? (
                  <>
                    <div className={styles.subtitlesba}>
                      Weighted Average Coupon
                    </div>
                    <div className={styles.content}>
                      {(currentYield || 0)?.toFixed(6)}%
                    </div>
                  </>
                ) : (
                  <SkeletonHeader />
                )}
              </div>
              <div className={styles.box}>
                {!isOpenAssetLoading ? (
                  <>
                    <div className={styles.subtitlesba}>
                      Total Current Loan Balance (USD)
                    </div>
                    <div className={styles.content}>
                      {isOrderBook ? '$' + Number(currentLoanBalance).toLocaleString() : formatCurrencyWithBillion(currentLoanBalance, 2)}
                    </div>
                  </>
                ) : (
                  <SkeletonHeader />
                )}
              </div>
              <div className={styles.box}>
                {currentYield ? (
                  <>
                    <div className={styles.subtitlesba}>Listing Type</div>
                    <div className={styles.content}>
                      {isOrderBook ? "Orderbook series" : capitalizeFirstLetter(sbaType) || "NA"}
                    </div>
                  </>
                ) : (
                  <SkeletonHeader />
                )}
              </div>
              {activeCurrency?.type === assestType.SBA7 && (
  <div className={styles.box}>
    {currentYield ? (
      <>
        <div className={styles.subtitlesba}>Loan Type</div>
        <div className={styles.content}>
          {LOAN_NAME[loanType] || "NA"}
        </div>
      </>
    ) : (
      <SkeletonHeader />
    )}
  </div>
)}
            </div>
          </div>
          {/* <div className={styles.list}>{renderPerDay}</div> */}
        </>
      ) : (
        <>
          <div
            className={cn(styles.details, {
              [styles.musicDetails]: assetType === "music",
            })}
          >
            <div className={styles.box}>
              {symbol === "" ? (
                <div className={styles.line}>
                  <div className={styles.img}>
                    <SkeletonLogo />
                  </div>
                  <div className={styles.skeleton_asset}>
                    <SkeletonHeader />
                  </div>
                </div>
              ) : (
                <div className={styles.line}>
                  <div
                    className={`${styles.img} ${
                      assetType === music ? styles.music_img : ""
                    }`}
                  >
                    {getLogo}
                    {!!ACCREDITATION_LABEL[accreditationType || ""] &&
                      <img className={styles.accreditationLabel} src={`/media/icon/${accreditationType}.svg`} alt="Accreditation" />}
                  </div>
                  <div>
                    <div className={styles.info} data-tooltip-id={assetInfo}>
                      {assetType === music
                        ? symbol ?? "--"
                        : assetInfo}
                    </div>
                    {/* {assetType !== music && <ReactTooltip className="theme-tooltip" id={assetInfo} place="right" content={assetInfo} />} */}
                    {assetType === music ? (<>
                      <div className={styles.songName} data-tooltip-id={name}>{name}</div>
                        {/* <ReactTooltip className="theme-tooltip" id={name} place="right" content={name} /> */}
                    </>) : (
                      ""
                    )}
                    <div className={styles.stock_name} data-tooltip-id={assetStockName}>
                      {assetType === music ? (
                        <div className={styles.music_release}>
                          <span>{formatDuration(duration ?? "0")}</span>
                          <i className="ri-checkbox-blank-circle-fill" />
                          {releaseDate?.split("-")[0] ?? "0000"}
                        </div>
                      ) : (
                        assetStockName
                      )}
                    </div>
                    {/* {assetType !== music && <ReactTooltip className="theme-tooltip" id={assetStockName} place="right" content={assetStockName} />} */}
                  </div>
                </div>
              )}
            </div>
            <div className={styles.box}>
              {userSelectedCurrency?.code &&
              !isOpenAssetLoading &&
              marketPrice !== 0 ? (
                <div className={styles.line1}>
                  <Price
                   marketPrice={assetType === music ? amount : (lastTradedPrice || amount)}
                    change={change || 0}
                    changesPercentage={changesPercentage || 0}
                    symbol={rawSymbol ?? symbol}
                  />
                  {assetType === music && (
                    <div className={styles.music_devide}></div>
                  )}
                </div>
              ) : (
                <div className={styles.Lheader}>
                  <SkeletonHeader />
                </div>
              )}
            </div>
            {assetType !== music && !LOAN_ASSETS[assetType] && (
              <div>
                {assetType !== music && !LOAN_ASSETS[assetType] && (
                  <AddWatchlist item={activeCurrency} />
                )}
              </div>
            )}
          </div>
          <div className={styles.list}>{renderPerDay}</div>
          {assetType === music && (
            <div className={styles.music_other_details}>
              <div className={styles.music_other_details__left}>
                <div className={`${styles.img} ${styles.albom_img}`}>
                  {musicArtistLogo}
                </div>
                <div className={styles.album}>{artist?.name}</div>
              </div>
              <div className={styles.music_other_details__right}>
                {/* TODO: svgs can be extracted as global svg and we can use them here as well */}
                {Object.keys(previewUrls || {}).map((platform: any) => {
                  let label = platform.split("_").join("").split("url")[0];
                  if (label === "itunes") label = "apple";
                  if (label === "amazonmusic") {
                    return (
                      <div
                        key={label}
                        onClick={() =>
                          window.open(previewUrls[platform], "_blank")
                        }
                        style={{ width: "24px", height: "24px" }}
                      >
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g clipPath="url(#clip0_6382_17110)">
                            <path d="M24 0H0V24H24V0Z" fill="#25D1DA" />
                            <path
                              d="M8.625 10.125H3.375V12H8.625V10.125Z"
                              fill="#14191A"
                            />
                            <path
                              d="M8.625 12.75H3.375V14.625H8.625V12.75Z"
                              fill="#14191A"
                            />
                            <path
                              d="M8.625 15.375H3.375V17.25H8.625V15.375Z"
                              fill="#14191A"
                            />
                            <path
                              d="M8.625 18H3.375V19.875H8.625V18Z"
                              fill="#14191A"
                            />
                            <path
                              d="M14.625 15.375H9.375V17.25H14.625V15.375Z"
                              fill="#14191A"
                            />
                            <path
                              d="M14.625 18H9.375V19.875H14.625V18Z"
                              fill="#14191A"
                            />
                            <path
                              d="M20.625 12.75H15.375V14.625H20.625V12.75Z"
                              fill="#14191A"
                            />
                            <path
                              d="M20.625 15.375H15.375V17.25H20.625V15.375Z"
                              fill="#14191A"
                            />
                            <path
                              d="M20.625 18H15.375V19.875H20.625V18Z"
                              fill="#14191A"
                            />
                          </g>
                          <defs>
                            <clipPath id="clip0_6382_17110">
                              <rect
                                width="24"
                                height="24"
                                rx="2"
                                fill="white"
                              />
                            </clipPath>
                          </defs>
                        </svg>
                      </div>
                    );
                  }
                  return (
                    previewUrls[platform] && (
                      <i
                        key={label}
                        onClick={() =>
                          window.open(previewUrls[platform], "_blank")
                        }
                        className={`ri-${label}-fill ${styles[label]}`}
                      ></i>
                    )
                  );
                })}
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
};
