import { useNetwork } from '@hooks/network'
import { useNotification } from '@hooks/notification'
import { APIS } from 'constant'
import  { useCallback } from 'react'

const UseBuySellApi = () => {
    const {patch:CancelOrderVerify,statusCode}=useNetwork()
    const {successNotification,errorNotification}=useNotification()

    const CancelOrderVerifcation=useCallback((id:string|number)=>{
        CancelOrderVerify(`${APIS.ORDERS_EXCHANGE}/${id}`,{orderType: "ORDER_DECLINED", "signature": "","isCancel": true}).then((res)=>{
         if(res?.success){
          successNotification(res?.message)
         }else {
          if (statusCode === 400) {
            errorNotification(res?.message);
          } else {
            errorNotification("Order expired. Please try again");
          }
        }
        })
    },[CancelOrderVerify, errorNotification,statusCode, successNotification])
    
  return (
   {CancelOrderVerifcation}
  )
}

export default UseBuySellApi