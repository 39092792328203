import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useCookie, useNetwork, useNotification, usePrevious } from "hooks";
import { ROUTES } from "routes";
import { ConfirmIdentity } from "./confirm-identity";
import styles from "./compliance.module.sass";
import {
  complianceDataState,
  signUpInfoState,
  loadingState,
  linkedDeviceState,
  userPersonalDetails,
  isStartVerificationNewState,
  reinitiateKycState,
  isIframeState,
  userDetailsState,
} from "states";
import { useRecoilValue, useSetRecoilState, useRecoilState } from "recoil";
import { URL_HOST } from "helpers";
import { signupFormMessage } from "./constants";
import { APIS } from "constant";
import { KycReview } from "./kyc-review";
import { KycStart } from "./kyc-start";
import { Agree } from "./agree";
import { redirect } from "react-router-dom";
import { DvpIframe } from "@views/account/components/DvpModals/dvpIframe";
import { Loader } from "@components/Loader2";
import { Json } from "../../../../types/common";
import classNames from "classnames";

interface IFormData {
  firstName: string;
  lastName: string;
  email: string;
}

interface IDisabledFormData {
  firstName?: boolean;
  lastName?: boolean;
  email?: boolean;
}

interface IShowFormDataError {
  isFirstNameError: boolean;
  isLastNameError: boolean;
  isEmailError: boolean;
}

interface ICompliance {
  isEditingUserDetails?: boolean;
  handleSuccess?: () => void;
  handleClose?: () => void;
  disabledData?: IDisabledFormData;
  setVerificationDone?: (val: boolean) => void;
  handleCloseModal?: () => void;
}

export const Compliance = ({
  isEditingUserDetails = false,
  handleSuccess,
  disabledData,
  handleClose,
  setVerificationDone,
  handleCloseModal
}: ICompliance) => {
  const [iframeLoading, setIframeLoading] = useState(false);
  const complianceData = useRecoilValue(complianceDataState);
  const devicelist = useRecoilValue(linkedDeviceState);
  const [reinitiateKyc, setReinitiateKyc] = useRecoilState(reinitiateKycState);
  const formData = useRecoilValue(signUpInfoState);
  const userDetails = useRecoilValue(userDetailsState);
  const [isStartVerification, setIsStartVerification] = useRecoilState(
    isStartVerificationNewState
  );
  const prevUserData = usePrevious(complianceData);
  const [isConfirmed, setIsConfirmed] = useState(false);
  const setUserLoading = useSetRecoilState(loadingState);
  const [isEmailLoading, setEmailLoading] = useState(false);
  const [personalData, setPersonalData] = useRecoilState(userPersonalDetails);
  const parentRef = useRef<HTMLDivElement | null>(null);
  const setIframeExists = useSetRecoilState(isIframeState);
  const [showFormDataError, setShowFormDataError] =
    useState<IShowFormDataError>({
      isFirstNameError: false,
      isLastNameError: false,
      isEmailError: false,
    });

  const { LOGIN } = ROUTES;
  const { deleteCookie } = useCookie();
  const { get: getCookieData } = useCookie();
  const { patch, data, loading } = useNetwork();
  const { errorNotification, successNotification } = useNotification();
  const { patch: removeLinkedDevice } = useNetwork();
  const [isReinitiate, setReinitiate] = useState<boolean>(false);
  const [reinitiateData, setReinitiateData] = useState({});
  const { get: getReIntiate, data: reInitiateData } = useNetwork();

  const {
    kycStatus,
    isOnboardingComplete,
    status,
    kybStatus,
    amlStatus,
    questionnaireStatus,
    accreditationStatus,
    signDocStatus,
  } = complianceData.onboardingData;

  const isAnyStatusPending = [
    kycStatus,
    kybStatus,
    amlStatus,
    accreditationStatus,
    questionnaireStatus,
    status,
    signDocStatus,
  ].some((status) => status === "pending");

  const isAnyRejected = [
    kycStatus,
    kybStatus,
    amlStatus,
    questionnaireStatus,
    accreditationStatus,
    signDocStatus,
  ].some((status) => status === "rejected");

  const logoutAction = () => {
    const registrations = JSON.parse(
      localStorage.exchageAppRegistration || "[]"
    );
    localStorage.clear();
    deleteCookie("userDetails");
    deleteCookie("userDetails");
    window.location.href = LOGIN;
  };

  useEffect(() => {
    if (parentRef.current) {
      const iframe = parentRef.current.querySelector("iframe");
      setIframeExists(!!iframe);
    } else {
      setIframeExists(false);
    }
  }, [complianceData]);

  useEffect(() => {
    window.addEventListener("message", handleMessageEvent);
    return () => {
      window.removeEventListener("message", handleMessageEvent);
    };
  }, []);

  const handleMessageEvent = (event: Json) => {
    const receivedData = event.data;
    if (receivedData?.type === "onComplete") {
      !!handleCloseModal && handleCloseModal()
    }
  };


  const onLogout = useCallback(() => {
    //remove all local storage expect but dont want to exchageAppRegistration
    //can not done one by one so keep in one varibale before delelating all localstorage
    //and create again localstorage with same key
    const userDetails = getCookieData("userDetails");

    const currentDeviceDetail = devicelist.find(
      (dev: any) => dev?.deviceToken === userDetails?.token
    );
    const payload = { ids: [currentDeviceDetail?._id], origin: "web" };
    removeLinkedDevice(
      `${APIS.UsersDeviceLogin}/${currentDeviceDetail?.userId}`,
      payload,
      { apiResponse: true }
    ).then((resp) => {
      logoutAction();
    }).catch((err) => {
      logoutAction();
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [devicelist]);

  const {
    firstNameMessage,
    lastNameMessage,
    emailMessage,
    InvalidEmailMessage,
  } = useMemo(() => signupFormMessage, []);

  const validateName = (name: string) => {
    const regex = /^[a-zA-Z]+$/i;
    return !(!name || regex.test(name) === false);
  };

  const validateEmail = (email: string) => {
    const regex =
      /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    return !(!email || regex.test(email) === false);
  };

  useEffect(() => {
    if (data) {
      if (data?.success) {
        setUserLoading(false);
        !!handleSuccess && handleSuccess();
        successNotification(data?.message);
      } else {
        setUserLoading(false);
        if (data?.message) {
          errorNotification(data?.message ?? "Something went wrong");
        } else {
          setIsConfirmed((prev) => !prev);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const handleProceed = useCallback(async () => {
    setEmailLoading(true);
    const { firstName = "", lastName = "", email } = formData;

    // Initialize an array to store error messages
    const errorMessages = [];

    // if (firstName) {
    //   console.log("firstName", firstName)
    //   if (!validateName(firstName.trim())) {
    //     errorMessages.push("Please enter alphabets only");
    //     setShowFormDataError((prev) => ({
    //       ...prev,
    //       isFirstNameError: true,
    //     }));
    //   } else {
    //     setShowFormDataError((prev) => ({
    //       ...prev,
    //       isFirstNameError: false,
    //     }));
    //   }
    // } else {
    //   setShowFormDataError((prev) => ({
    //     ...prev,
    //     isFirstNameError: true,
    //   }));
    //   errorMessages.push(firstNameMessage);
    // }

    // if (lastName) {
    //   if (!validateName(lastName.trim())) {
    //     errorMessages.push("Please enter alphabets only");
    //     setShowFormDataError((prev) => ({
    //       ...prev,
    //       isLastNameError: true,
    //     }));
    //   } else {
    //     setShowFormDataError((prev) => ({
    //       ...prev,
    //       isLastNameError: false,
    //     }));
    //   }
    // } else {
    //   setShowFormDataError((prev) => ({
    //     ...prev,
    //     isLastNameError: true,
    //   }));
    //   errorMessages.push(lastNameMessage);
    // }

    if (email) {
      if (!validateEmail(email)) {
        errorMessages.push(InvalidEmailMessage);
        setShowFormDataError((prev) => ({
          ...prev,
          isEmailError: true,
        }));
      } else {
        setShowFormDataError((prev) => ({
          ...prev,
          isEmailError: false,
        }));
      }
    } else {
      setShowFormDataError((prev) => ({
        ...prev,
        isEmailError: true,
      }));
      errorMessages.push(emailMessage);
    }

    if (errorMessages.length > 0) {
      setEmailLoading(false);
      // errorNotification(errorMessages.join("\n"));
    } else {
      const payload = {
        firstName: firstName,
        lastName: lastName,
        email: email.toLocaleLowerCase(),
      };
      await patch(`${APIS.Users}/${complianceData.id}`, payload);
      setUserLoading(true);
      setPersonalData((prev) => ({
        ...prev,
        email: email.toLocaleLowerCase(),
      }));
      setEmailLoading(false);
    }
    // setIsStartVerification(true)
  }, [
    InvalidEmailMessage,
    complianceData.id,
    emailMessage,
    errorNotification,
    firstNameMessage,
    formData,
    lastNameMessage,
    patch,
    setUserLoading,
  ]);

  const { onboardingData, phone, countryCode } = complianceData;

  useEffect(() => {
    if (reinitiateKyc && userDetails?.data?.sessionId) {
      getReIntiate("/reinitiate-session").then((res: any) => {});
    }
  }, [getReIntiate, reinitiateKyc]);

  const isVerfificationComplete = useMemo(() => {
    const isOnboardingCompleted = onboardingData?.status === "completed";
    const hasNoRejections = !isAnyRejected;

    if (
      (status === "completed" && hasNoRejections && !reinitiateKyc && !isAnyStatusPending)
      ||
      (isOnboardingComplete && hasNoRejections && !isAnyStatusPending)
      ||
      (prevUserData?.onboardingData?.status === "pending" && isOnboardingCompleted && hasNoRejections && !isAnyStatusPending)
      ||
      ((status === "pending" || (isOnboardingCompleted && (isAnyRejected || isAnyStatusPending))) && personalData?.sessionId && !reinitiateKyc)
    ) {
      return true;
    }
    return false;
  }, [
    isAnyRejected,
    isAnyStatusPending,
    isOnboardingComplete,
    onboardingData?.status,
    prevUserData?.onboardingData?.status,
    reinitiateKyc,
    status,
  ]);

  useEffect(() => {
    if (isVerfificationComplete && setVerificationDone) {
      setVerificationDone(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isVerfificationComplete]);

  useEffect(() => {
    if (reInitiateData) {
      setReinitiateData(reInitiateData?.data?.url);
    }
  }, [reInitiateData]);

  const handleLoadComplete = () => {
    setIframeLoading(false);
    console.log("Iframe loading completed.");
  };

  const renderComponent = useMemo(() => {
    let url = `${
      onboardingData?.complexUrl
    }&allow=true&phone=${phone}&countryCode=${countryCode}${
      personalData?.email && `&email=${personalData?.email}`
    }&redirect=false`;
    if (reInitiateData) {
      url = `${
        reInitiateData.data.url
      }&allow=true&phone=${phone}&countryCode=${countryCode}${
        personalData?.email && `&email=${personalData?.email}`
      }&redirect=false`;
    }

    const isOnboardingCompleted = onboardingData?.status === "completed";

    if (
      !isStartVerification && !personalData?.sessionId &&
      (status === "pending" ||
        (isOnboardingCompleted && (isAnyRejected || isAnyStatusPending))) &&
      !isEditingUserDetails
    ) {
      return (
        <div className={styles.main_container}>
          <KycStart />
          <button
            className={`${styles.proceed_btn} ${styles.kyc_btnStart}`}
            onClick={() => {
              setIframeLoading(true);
              setIframeExists(true);
              setIsStartVerification(true);
            }}
          >
            Start verification
          </button>
        </div>
      );
    }

    if (!isEditingUserDetails) {
      if (isVerfificationComplete) {
        return (
          <div className={styles.main_container}>
            <KycReview
              onLogout={onLogout}
              setReinitiateKyc={setReinitiateKyc}
            />
          </div>
        );
      }

      return (
        <div className={!iframeLoading ? styles.main_container : ""} ref={parentRef}>
          <iframe
            src={url}
            title="Compliance"
            className={styles.compliance_iframe}
            allow="camera; microphone; geolocation"
            onLoad={handleLoadComplete}
            style={{ display: iframeLoading ? "none" : "" }}
          />
          {iframeLoading && (
            <div className={styles.compliance_iframe_loader}>
              <Loader className="loader-blue" />
            </div>
          )}
        </div>
      );
    }

    return (
      <div className={styles.main_container}>
        <ConfirmIdentity
          disabledData={disabledData}
          proceed={handleProceed}
          loading={loading}
          showFormDataError={showFormDataError}
          setShowFormDataError={setShowFormDataError}
        />
        <div className="compliance-btn-groups">
          <button
            className={`btn-cancel ${styles.proceed_btn} ${styles.kyc_btn}`}
            onClick={() => !!handleClose && handleClose()}
          >
            Cancel
          </button>
          <button
            className={classNames("btn-save-verify", styles.proceed_btn, styles.kyc_btn, {[styles.disbaled_btn]:isEmailLoading || !formData?.email} )}
            onClick={handleProceed}
            disabled={isEmailLoading || !formData?.email}
          >
            {isEmailLoading ? <Loader dimension={20} /> : "Save and verify"}
          </button>
        </div>
      </div>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    onboardingData?.complexUrl,
    onboardingData?.status,
    phone,
    countryCode,
    personalData?.email,
    reInitiateData,
    isStartVerification,
    status,
    isAnyRejected,
    isAnyStatusPending,
    isEditingUserDetails,
    disabledData,
    handleProceed,
    loading,
    showFormDataError,
    userDetails?.data?.sessionId,
    setIframeExists,
    setIsStartVerification,
    isVerfificationComplete,
    onLogout,
    setReinitiateKyc,
    handleClose,
    iframeLoading,
  ]);

  return <>{renderComponent}</>;
};
