import env from "@beam-australia/react-env";

export const REACT_APP_API_HOST = env("API_HOST");
export const API_HOST_2 = env("API_HOST_2");
export const REACT_APP_STRIPE_SECRET = env("STRIPE_SECRET");
export const REACT_APP_HOST_URL = env("HOST_URL");
export const ENVIRONMENT = env("ENVIRONMENT");
export const REACT_APP_FIREBASE = env("FIREBASE");
export const SOCKET_URL = env("SOCKET_URL");
export const ALLOW_MPC_ENV = JSON.parse(env("ALLOW_MPC") || "false");
