import { useMemo } from "react";
import { useRecoilValue } from "recoil";

import { WatchlistState } from "states";
import { WatchlistItem } from "./watchlist-item";
import useDarkMode from "use-dark-mode";
import styles from "../Currency/Currency.module.sass";
import { Image } from "@storybook";



export const Watchlist = () => {
  const watchlist = useRecoilValue(WatchlistState);
  const darkMode = useDarkMode();
  const renderWatchlist = useMemo(
    () =>
      watchlist?.map((item, index) => {
        return <WatchlistItem amount= {0} lastTradedPrice= {0} key={item._id} item={item} index={index} />;
      }),
    [watchlist]
  );

  return (
    <>
      {watchlist?.length === 0 ? (
        <div className={styles.noData}>
          <div className={styles.noDataContent}>
            <Image
              fileName={`images/${
                darkMode.value ? "no_data_dark.svg" : "no_data_light.svg"
              }`} width= {"120px"}
            />
            <div className={styles.noDataHeading}>Your Watchlist is empty</div>
            <div className={styles.noDataPara}>Add assets to your watchlist to keep track of their performance.</div>
          </div>
        </div>
      ) : (
        <>
          { renderWatchlist }
        </>
      )}
    </>
  );
};
