import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useCookie, useNetwork, usePrevious, useLocalStorage } from "hooks";
import NewModal from "components/new-modal/new-modal";
import { APIS } from "constant";
import {
  useRecoilCallback,
  useRecoilState,
  useSetRecoilState,
  useRecoilValue,
} from "recoil";
import {
  TriggerUserApi,
  complianceDataState,
  loadingState,
  userAllDetailsState,
  userSelectedCurrencyState,
  openComplianceState,
  userPersonalDetails,
  allCoOwnerState,
  coOwnerApiCall,
   reOpenKycSignupModalState,
  isIframeState,
  isStartVerificationNewState,
} from "states";
import { Compliance } from "views/exchange/components/compliance";
import { useLocation } from "react-router-dom";
import { SimplciConfirmation } from "@storybook";
import './kyc-signup-modal.scss'

export const KycSignupModal = () => {
  // local state
  const [openCompliance, setOpenCompliance] =
    useRecoilState(openComplianceState);
  const setReOpenKycSignupModal = useSetRecoilState(reOpenKycSignupModalState);
  const [isGettingUser, setIsGettingUser] = useState(false);
  const [userDetails, setUserDetails] = useRecoilState(userAllDetailsState);
  const [complianceDataValue, setComplianceData] =
    useRecoilState(complianceDataState);
  const setSelectedCurrency = useSetRecoilState(userSelectedCurrencyState);
  const setUserLoading = useSetRecoilState(loadingState);
  const setTriggerUserApi = useSetRecoilState(TriggerUserApi);
  const [isSuccessModal, setIsSuccessModal] = useState(true);
  const setPersonalData = useSetRecoilState(userPersonalDetails);
  const timeoutValue = useRef<any>(null);
  const coOwnerList = useRecoilValue(allCoOwnerState);
  const iscoOwnerApiCall = useRecoilValue(coOwnerApiCall);
  const [isVerificationClose, setVerificationClose] = useState(false);
  const [isVerificationDone, setVerificationDone] = useState(false);

  const isStartVerification = useRecoilValue(
    isStartVerificationNewState
  );
  const { get: getLocalStorage } = useLocalStorage();

  const iframeExists = useRecoilValue(isIframeState);

  const location = useLocation();

  
  // hooks
  const { get: getUser, data: userData } = useNetwork();
  const { get: getCookieData } = useCookie();
  const prevUserData = usePrevious(userData);
  //   const agreeModal = localStorage.getItem("AgreeModal");

  const { status, isOnboardingComplete , kycStatus,
    kybStatus,
    amlStatus,
    questionnaireStatus,
    accreditationStatus,
    signDocStatus, } = complianceDataValue.onboardingData;
  
  const isAnyStatusPending = [
    kycStatus,
    kybStatus,
    amlStatus,
  ].some((status) => status === "pending" || status === "processing");
  
  const isAnyRejected = [
    kycStatus,
    kybStatus,
    amlStatus,
    questionnaireStatus,
    accreditationStatus,
    signDocStatus,
  ].some((status) => status === "rejected");

  useEffect(() => {
    const userDetails = getCookieData("userDetails");
    try {
      if (userDetails && !isOnboardingComplete) {
        getUser(APIS.Users);
      }
    } catch (err) {
      alert("Something went wrong.");
    }
    if (openCompliance && isSuccessModal) {
      timeoutValue.current = setTimeout(() => {
        setIsGettingUser((prev) => !prev);
      }, 5000);
    }
    return () => {
      clearTimeout(timeoutValue.current);
    };
  }, [
    isGettingUser,
    openCompliance,
    getCookieData,
    getUser,
    isOnboardingComplete,
  ]);

  useEffect(() => {
    if (userData?.data) {
      const {
        onboardingData = {},
        id,
        email,
        countryCode,
        phone,
        firstName,
        lastName,
      } = userData?.data ?? {};
      if (
        prevUserData?.data?.onboardingData?.status === "pending" &&
        onboardingData?.status === "completed"
      ) {
        setPersonalData((prev) => ({
          ...prev,
          firstName,
          lastName,
          email,
        }));
      }
      if (
        onboardingData?.status === "pending" || (  onboardingData?.status === "completed" && (isAnyRejected || isAnyStatusPending) )||
        (localStorage.getItem("isNewUser") === 'true' &&
          onboardingData?.status === "completed")
      ) {
        localStorage.removeItem("isNewUser");
        setOpenCompliance(true);
        const complianceData = {
          onboardingData,
          id,
          email,
          countryCode,
          phone,
          firstName,
          lastName,
        };
        setComplianceData(complianceData);

        return;
      }
      const complianceData = {
        onboardingData,
        id,
        email,
        countryCode,
        phone,
        firstName,
        lastName,
      };
      setComplianceData(complianceData);
      if (
        prevUserData?.data?.onboardingData?.status === "pending" &&
        onboardingData?.status === "completed"
      ) {
        setOpenCompliance(true);
        // setIsSuccessModal(true);
      }

      //   if (
      //     agreeModal !== "false" &&
      //     onboardingData?.isOnboardingComplete === true
      //   ) {
      //     setOpenCompliance(true);
      //     return;
      //   }
      setUserLoading(false);
      setUserDetails(userData.data);
      setSelectedCurrency(userData.data.settings.currency);
      return;
    }
  }, [setComplianceData, setSelectedCurrency, setUserDetails, userData, setUserLoading, prevUserData?.data?.onboardingData?.status, prevUserData?.data?.onboardingData.isOnboardingComplete, setOpenCompliance, setPersonalData]);
  const handleAfterSuccess = useCallback(() => {
    setTriggerUserApi((prev) => !prev);
  }, []);

  const handleCloseModal = useCallback(() => {
    setTriggerUserApi((prev) => !prev)
    setIsSuccessModal(false);
    setOpenCompliance(false);
    setReOpenKycSignupModal(false)
  }, []);


  const onCancelVerification=()=>{
    if(!isStartVerification || isVerificationDone){
      handleCloseModal()
      return
    }
    setVerificationClose(prev=>!prev)
  }

  const isOwner = useMemo(() => {
    const primaryUser: any =
      getLocalStorage("dropdown")?.["isPrimary" as any] ?? "";
    if (iscoOwnerApiCall) {
      if (coOwnerList?.length > 0 && !primaryUser) {
        return false;
      }
      return true;
    }
  }, [coOwnerList, iscoOwnerApiCall]);

  return (
    <>
      {isOwner && (
        <NewModal
          isOpen={openCompliance && isSuccessModal}
          closeModal={onCancelVerification}
          modalName={"Compliance"}
          showCloseBtn={true}
          isStopOutsideClick={false}
          closeOnEscBtn={false}
          className={`${
            iframeExists && "Modal__kyc"
          } Modal__wrapper exchange-modal-wrapper model-title-margin`}
        >
          <>
            {isVerificationClose&&
            <div className="SimplciConfirmationDialog">
              <div className="SimplciConfirmationDialog--container">
              <SimplciConfirmation
                  title={"Cancel verification process"}
                  visible={isVerificationClose}
                  description={
                    "Are you sure you want to cancel your ongoing verification? We suggest that you complete your verification."
                  }
                  handleModal={handleCloseModal}
                  handleClose={onCancelVerification}
                  boldDescription=""
                  label="Yes"
                  cancelLabel="No"
                  type="sell"
                />
              </div>
            </div>
            }
              <Compliance handleSuccess={handleAfterSuccess} setVerificationDone={setVerificationDone} handleCloseModal={handleCloseModal} />
          </>
        </NewModal>
      )}
    </>
  );
};
