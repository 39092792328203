import React from "react";
import useDarkMode from "use-dark-mode";

import { Image } from "@storybook";

import "./TradingUnavailable.scss"; // Import the CSS file

export const TradingUnavailable: React.FC = () => {
  const darkMode = useDarkMode(false);

  return (
    <div className="trading-unavailable">
      <div className="icon-container">
        <Image
          className=""
          fileName={
            darkMode.value ? "images/no-chart.svg" : "images/no-chart-light.svg"
          }
        />
      </div>
      <h2>Trading services are temporarily unavailable</h2>
      <p>
        We are experiencing a technical issue. We apologize for the
        inconvenience and appreciate your understanding. Please try again later.
      </p>
    </div>
  );
};
