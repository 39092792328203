// import { Header } from "components";
import { useCookie } from "hooks";
import { useEffect, useMemo } from "react";
import cn from "classnames";

import "./api-designer.scss";
import { useRecoilValue } from "recoil";
import { CollapseSideBarState, showEmailNotificationState } from "states";

declare global {
  namespace JSX {
    interface IntrinsicElements {
      "api-designer": any;
    }
  }
}

export const APIDesigner = () => {
  const { get: getCookieData } = useCookie();
  const isCollapsed = useRecoilValue(CollapseSideBarState);
  const emailNotificationValue = useRecoilValue(showEmailNotificationState);
  const userDetails = getCookieData("userDetails");
  const { token } = userDetails ?? {};

  useEffect(() => {
    localStorage.setItem("dark", "false");
  }, []);

  const renderWebComponent = useMemo(
    () => (
      <div
        // style={{ position: "inherit", top: 62, left: 0, right: 0 }}
        dangerouslySetInnerHTML={{
          __html: `<api-designer
          token=${token}
          sidebar="false"
          isdarkMode="false"
          logout="false"
          topbar="false"
          url_prefix="admin/api-designer"
          height= ${142}
          width=${250}
        />`,
        }}
      />
    ),
    [token]
  );

  const heightForEmailNotification = (emailNotificationValue == true) ? "calc( 100vh - 44px )" : "auto";
  return (
    <div className="apidesignerbody" style={{ height: heightForEmailNotification }}>
      <div
        className={cn(
          "apidesignerContainer",
          isCollapsed ? "collapsed" : "decollapse"
        )}
      >
        {renderWebComponent}
      </div>
    </div>
  );
};
