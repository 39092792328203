import { atom } from "recoil";

interface User {
  isActive: boolean;
  isTradeEnabled: boolean;
  isTransactionEnabled: boolean;
  name: string;
  ownerId: string;
  profileImage: string;
  type: string;
  _id: string;
  isPrimary?: boolean;
  customerId?: string;
  phone?:string
  countryCode?:string
}

interface IUsername {
  firstName: string;
  lastName: string;
}

export const userNameState = atom<IUsername | Record<string, never>>({
  key: "user-name-state",
  default: {
    firstName: "",
    lastName: "",
  },
});

export const userEmailState = atom<string>({
  key: "user-email-state",
  default: "",
});

export const userMobileNumberState = atom<{
  phone: string;
  countryCode: string;
}>({
  key: "user-mobile-number-state",
  default: {
    phone: "",
    countryCode: "+1",
  },
});

export const userTypeState = atom<string>({
  key: "user-type-state",
  default: "co-owner",
});

export const tradingPermissionState = atom<boolean>({
  key: "trading-permission-state",
  default: true,
});
export const transactionPermissionState = atom<boolean>({
  key: "transaction-permission-state",
  default: true,
});

export const InviteFullNameInputState = atom<boolean>({
  key: "invite-full-name-input-key",
  default: false,
});

export const InviteFirstNameInputState = atom({
  key: "invite-first-name-input-key",
  default: false,
});

export const InviteLastNameInputState = atom({
  key: "invite-last-name-input-key",
  default: false,
});
export const InvitePhoneInputState = atom({
  key: "invite-phone-input-key",
  default: false,
});

export const InviteEmailInputState = atom<boolean>({
  key: "invite-email-input-key",
  default: false,
});

export const allCoOwnerState = atom<User[]>({
  key: "all-co-owner-state",
  default: [],
});

export const userDropDownState = atom<any>({
  key: "user-drop-down-state",
  default: JSON.parse((localStorage.getItem("dropdown") as string)) || {isPrimary: true},
});

export const coUserDetailsLoading = atom({
  key: "co-user-details-loading",
  default: false,
});
export const DisplayUserState = atom({
  key: "display-user-state",
  default: {},
});

export const coOwnerApiCall = atom<boolean>({
  key: "co-owner-api-call",
  default: false,
});
