import React, {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";

import styles from "../VerificationIncomplete/verificationIncomplete.module.sass";
import { TriggerUserApi, userDetailsState } from "@states/user";
import { useRecoilValue, useSetRecoilState } from "recoil";
import useDarkMode from "use-dark-mode";
import { ActiveCurrencyState } from "@states/exchange";
import { useNetwork } from "@hooks/network";
import { APIS } from "../../../../../../constant";
import { useNotification } from "@hooks/notification";
import { Loader } from "components";
import {
  ACCREDITATION_TEXT,
  ACCREDITATION_TYPES,
} from "@views/exchange/components/Main/constants";
// @ts-ignore
import { Json } from "@types/common";

interface IUpgradeAccreditation {
  isProfile?: boolean;
  onClose?: () => void;
}

export const UpgradeAccreditation = ({
  isProfile,
  onClose,
}: IUpgradeAccreditation) => {
  const darkmode = useDarkMode(false);
  const userDetails = useRecoilValue(userDetailsState);
  const activeCurrency = useRecoilValue(ActiveCurrencyState);
  const setTriggerUserApi = useSetRecoilState(TriggerUserApi);
  const { post: postAccreditation, loading } = useNetwork();
  const { errorNotification } = useNotification();

  const [accreditationUrl, setAccreditationUrl] = useState("");
  const [successView, setSuccessView] = useState(false);
  const [iframeLoading, setIframeLoading] = useState(false);

  const { accreditationDetails, onboardingData } = userDetails?.data || {};

  const { accreditationStatus, accreditationType } = onboardingData || {};
  const inProgressing = accreditationStatus === "processing";
  const accreStatus = new Set(["rejected", "processing"]);
  const accreType = accreStatus.has(accreditationStatus || "")
    ? accreditationDetails?.previousType
    : accreditationType;
  const accreditationTypeText =
    ACCREDITATION_TEXT[activeCurrency?.accreditationType] || "";

  useEffect(() => {
    window.addEventListener("message", handleMessageEvent);
    return () => {
      window.removeEventListener("message", handleMessageEvent);
    };
  }, []);

  const handleMessageEvent = (event: Json) => {
    const receivedData = event.data;
    if (receivedData?.type === "onComplete") {
      setSuccessView(true);
      setTriggerUserApi((prev) => !prev);
    }
  };

  const handleStartUpgrade = useCallback(async () => {
    const type = isProfile
      ? ACCREDITATION_TYPES.C506
      : activeCurrency?.accreditationType;
    const res = await postAccreditation(
      APIS.ACCREDITATION,
      { type },
      { apiResponse: true }
    );
    if (res?.message === "ok") {
      setIframeLoading(true);
      setAccreditationUrl(res?.data || "");
    } else
      return errorNotification(
        res?.message || "Something went wrong. Please try again"
      );
  }, [
    activeCurrency?.accreditationType,
    errorNotification,
    isProfile,
    postAccreditation,
  ]);

  const handleClose = useCallback(() => {
    !!onClose && onClose();
  }, [onClose]);

  const renderProfileUpgrade = useMemo(() => {
    return (
      <div className={styles.renderKycStatus}>
        <div className={styles.renderKycStatus__usersImg}>
          {!inProgressing ? (
            <img
              src={
                darkmode.value
                  ? "/media/icon/file-check-dark.svg"
                  : "/media/icon/file-check-light.svg"
              }
              alt=""
            />
          ) : (
            <img
              src={
                darkmode.value
                  ? "/media/icon/file-search-dark.svg"
                  : "/media/icon/file-search-light.svg"
              }
              alt=""
            />
          )}
        </div>
        <div className={styles.renderKycStatus__Texts}>
          <div className={styles.renderKycStatus__Texts__heading}>
            {!inProgressing ? (
              isProfile ? (
                "Upgrade to Accredited 506(c)"
              ) : (
                <>Accreditation {accreditationTypeText} is required to buy.</>
              )
            ) : (
              "Accreditation Under Review"
            )}
          </div>
          <div className={styles.renderKycStatus__Texts__subHeading}>
            {!inProgressing ? (
              isProfile ? (
                <>
                  Switching from Non-Accredited or 506(b) to 506(c) requires{" "}
                  <br /> additional verification steps.
                </>
              ) : (
                <>
                  You are an accreditation {ACCREDITATION_TEXT[accreType] || ""}{" "}
                  investor. Complete your <br /> accreditation{" "}
                  {accreditationTypeText} to buy.
                </>
              )
            ) : (
              <>
                We are in the process of reviewing your 506(c) accreditation,
                which <br /> may take 1-2 business days. We will notify you when
                it's approved. <br />
                Thank you for your patience.
              </>
            )}
          </div>
        </div>
        {!inProgressing && (
          <button
            className={`accreditation-btn ${styles.renderKycStatus__btn}`}
            onClick={handleStartUpgrade}
            disabled={loading}
          >
            {loading ? (
              <Loader className="loader-white" dimension={20} />
            ) : isProfile ? (
              "Get Accreditation 506(c)"
            ) : (
              `Complete Accreditation ${accreditationTypeText || ""}`
            )}
          </button>
        )}
        {inProgressing && (
          <button
            className={styles.renderKycStatus__closeButton}
            onClick={handleClose}
          >
            Close
          </button>
        )}
      </div>
    );
  }, [
    activeCurrency,
    userDetails,
    loading,
    darkmode.value,
    inProgressing,
    isProfile,
    handleStartUpgrade,
    handleClose,
  ]);

  const renderSuccess = useMemo(() => {
    return (
      <div className={styles.renderKycStatus}>
        <div className={styles.renderKycStatus__usersImg}>
          <i
            className={`ri-checkbox-circle-fill ${styles.renderKycStatus__icon}`}
          />
        </div>
        <div className={styles.renderKycStatus__Texts}>
          <div className={styles.renderKycStatus__Texts__heading}>
            {activeCurrency?.accreditationType !== ACCREDITATION_TYPES.C506 &&
            !isProfile
              ? "Accreditation successfully completed"
              : "Accreditation 506(c) request submitted"}
          </div>
          <div className={styles.renderKycStatus__Texts__subHeading}>
            {activeCurrency?.accreditationType !== ACCREDITATION_TYPES.C506 &&
            !isProfile ? (
              <>
                Your request for 506(b) accreditation has been successfully{" "}
                <br /> completed.
              </>
            ) : (
              <>
                Your request for 506(c) accreditation has been successfully{" "}
                <br /> submitted. The process takes 1-2 business days, and we
                will <br /> notify you once your accreditation is approved.
              </>
            )}
          </div>
        </div>
        <div
          className={styles.renderKycStatus__closeButton}
          onClick={handleClose}
        >
          Close
        </div>
      </div>
    );
  }, [activeCurrency?.accreditationType, isProfile, handleClose]);

  const handleLoadComplete = useCallback(() => {
    setTimeout(() => {
      setIframeLoading(false);
   } , 3000)
    console.log("Iframe loading completed.");
  },[])
  
  const renderIframe = useMemo(() => {
    return (
      <Fragment>
        <iframe
          src={accreditationUrl}
          title="Compliance"
          className="accreditation-iframe"
          allow="camera; microphone"
          onLoad={handleLoadComplete}
          style={{ display: iframeLoading ? "none" : "" }}
        />
        {iframeLoading && (
          <div className="accreditation-iframe__loading">
            <Loader className="loader-blue" />
          </div>
        )}
      </Fragment>
    );
  }, [accreditationUrl, iframeLoading ,handleLoadComplete]);

  const renderView = useMemo(() => {
    if (successView) return renderSuccess;
    else if (accreditationUrl) return renderIframe;
    return renderProfileUpgrade;
  }, [
    accreditationUrl,
    renderIframe,
    renderSuccess,
    renderProfileUpgrade,
    successView,
  ]);

  return <>{renderView}</>;
};
