import type { IReactResponsiveTable } from "./type";

import { FC, useEffect, useMemo, useRef, useState } from "react";

import classNames from "classnames";
import { Tooltip } from "@storybook";

import {
  formatSizeUnits,
  getFormattedDate,
  getDateWithTime,
  getTime,
  useFormatNumber,
} from "utils";

// import { useOutsideClick } from "hooks";

import "./react-responsive-table.scss";
import { useCurrency } from "../../hooks";
import { SkeletonTable } from "./components/loader";
import { TableEmptyMessage } from "./components/emptyTable";
import InfiniteScroll from "react-infinite-scroll-component";
import { v4 as uuidv4 } from "uuid";

export const ReactResponsiveTable: FC<IReactResponsiveTable> = ({
  downloadZip,
  tableType,
  column,
  rows = [],
  isLoading = false,
  showDateFilter = false,
  showFilter = false,
  showArchiveFilter = false,
  showSearch = false,
  className = "",
  rootClassName = "",
  columnHandle = false,
  handelRowClick,
  filterPage = "",
  showSelectedButton = false,
  handleEditInvestorDetails,
  numberOfList = 6,
  numberOfColumn = 0,
  error = false,
  collapsible = false,
  defaultComponent,
  showHeader = true,
  handleSelectAll = () => {},
  isAllSelected = false,
  scrollYDirectionLeft = false,
  disableColumn = [],
  disableRow = [],
  handleFetchMore = ()=> {},
  offsetValue = 0,
  filters = {},
}) => {
  // const filterRef = useRef();

  const [tableRowsData, setTableRows] = useState<any[]>(rows);
  const { formatCurrencyWithBillion } = useCurrency();
  const [collapseOpenItemId, setCollapseOpenItemId] = useState<any>([]);
  const scrollRef = useRef<any>(null);
  const hasScrolled = useRef(false); 

  // useOutsideClick(filterRef, () => {});


  useEffect(() => {
    setTableRows(rows);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rows]);

  const tableData = useMemo(() => tableRowsData ?? [], [tableRowsData]);

  const { numberDecimal } = useFormatNumber();

  const tableRows = useMemo(() => tableData ?? [], [tableData]);

  const filteredRows = useMemo(() => {
    //here we can manupulate tableRows as per requirements
    return tableRows;
  }, [tableRows]);

  const renderColumn = useMemo(
    () =>
      column.map(({ label, key, width }, index) =>
        key === "checkbox" ? (
          <th
            className="table__head-data"
            // eslint-disable-next-line react/no-array-index-key
            key={`renderColumns__${label}_${index}-${key}`}
            style={{ width }}>
            <input
              className="row-checkbox" type="checkbox" checked={isAllSelected}
              onChange={handleSelectAll}
            />
          </th>
        ) : (
          <th
            className="table__head-data"
            key={`renderColumns__${label}_${index}-${key}`}
            style={{ width }}>
            <div className="table__head-data--label">
              <span>{label} </span>
            </div>
          </th>
        ),
      ),
    [column, isAllSelected],
  );
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const setCollapsibleId = (item: any) => {
    if (collapseOpenItemId.indexOf(item.createdAt) >= 0) {
      setCollapseOpenItemId((prev: any) => [...prev.filter((x: any) => x !== item.createdAt)]);
    } else {
      setCollapseOpenItemId((prev: any) => [...prev, item.createdAt]);
    }
  };

  const renderRows = useMemo(() => {
    const disableRowsSet = new Set([...disableRow])
    const disableColumnSet = new Set([...disableColumn])
    const renderingRows = filteredRows;
    return renderingRows.map((item, index) => (
      <>
        <tr
          key={`renderRows_${item.createdAt}__${index} `}
          onClick={() => {
            // eslint-disable-next-line @typescript-eslint/no-unused-expressions
            handelRowClick ? handelRowClick(item) : {};
            collapsible && setCollapsibleId(item);
          }}
          className={`${columnHandle ? "row__hover" : ""} ${
            collapsible && item.instances?.length ? "collapssed_row" : ""
          } ${collapseOpenItemId.indexOf(item.createdAt) >= 0 ? "open_row" : ""} ${item?.marked ? "row-color" : ""}
          ${disableRowsSet.has(index) ? "disabled-row" : ""}`}>
          {column.map(({ key, format, width, className }, idx) => {
            let value = item[key];
            if (value || value >= 0) {
              switch (format) {
                case "number":
                  break;
                case "date":
                  value = `${getFormattedDate(value)}  ${getTime(value)}`;
                  break;
                case "dateTime":
                  value = getDateWithTime(value);
                  break;
                case "jsx":
                  value = value();
                  break;
                case "price":
                  value = formatCurrencyWithBillion(value, 2);
                  break;
                case "time":
                  value = getTime(value);
                  break;
                case "percentage":
                  value = `${value}%`;
                  break;
                case "size":
                  value = formatSizeUnits(value);
                  break;
                default:
                  break;
              }
            }
            value = value == null || value === "" ? "--" : value;
            const elepsisClass =
              format === "string"
                ? tableType === "pipeLine"
                  ? "text-elipsis-pipeline"
                  : "text-elipsis"
                : "";
            return (
              <td
                key={`renderColumn_${index}__${idx}__${key}`}
                className={`${className ? className : ""} ${disableColumnSet.has(idx) ? "disabled-column" : ""}`}
                style={{ width }}>
                {new Set(["name", "description"]).has(key) ? (
                  <Tooltip key={index} text={value} direction="right">
                    <div className={elepsisClass}>{value}</div>
                  </Tooltip>
                ) : (
                  <div className={elepsisClass}>{value}</div>
                )}
              </td>
            );
          })}
        </tr>
        {collapsible &&
          collapseOpenItemId.includes(item.createdAt) &&
          item.instances?.length > 0 && (
            <td className="collapsible_row" colSpan={column.length}>
              {defaultComponent(item, item.instances)}
            </td>
          )}
      </>
    ));
  }, [
    collapseOpenItemId,
    collapsible,
    column,
    columnHandle,
    defaultComponent,
    filteredRows,
    formatCurrencyWithBillion,
    handelRowClick,
    setCollapsibleId,
    tableType,
    disableColumn,
    disableRow,
  ]);

  useEffect(() => {
    if (scrollYDirectionLeft && !hasScrolled.current && filteredRows.length > 0) {
      scrollRef.current.scrollLeft = -scrollRef.current.scrollWidth;
      hasScrolled.current=true
    }
  }, [scrollYDirectionLeft, filteredRows.length]); 


  const reactTableResponsiveWrapper = useMemo(() => {
    return classNames("react-table-responsive-wrapper", {
      "react-table-responsive-fund-details-wrapper": tableType === "fund-details",
      "react-table-responsive-sessions-wrapper": tableType === "sessions",
      "react-table-responsive-agreement-document-modal-sessions-wrapper":
        tableType === "agreementdocumentmodal",
    });
  }, [tableType]);

  const scrollYDirectionLeftClass = scrollYDirectionLeft ? "table-visible-scroll" : "";
const scrollID = uuidv4();
  return (
    <div className={`react-table-responsive-container ${rootClassName || ""} ${scrollYDirectionLeftClass}`} ref={scrollRef} id={scrollID}>
      <div className={reactTableResponsiveWrapper}>
        <table className={`react-table-responsive fl-table ${className}`}>
            <InfiniteScroll
              dataLength={renderRows.length || 0}
              next={handleFetchMore}
              hasMore={
                renderRows.length >=
                offsetValue * filters?.limit + filters?.limit
              }
              loader={""}
              scrollableTarget={scrollID}
            >{showHeader && (
            <thead className="react-table-responsive__head">
            <tr
              className="react-table-responsive__head-row"
              style={{ backgroundColor: "#d9e1f2" }}>
              {renderColumn}
            </tr>
            </thead>
          )}

          {!!tableData.length && !!renderRows.length && ( 
            <tbody className="react-table-responsive__body ">{renderRows}</tbody>
          )}
          </InfiniteScroll>
        </table>
        {(!tableData.length || error) && !isLoading && (
          <TableEmptyMessage height={"calc(100vh - 400px)"} />
        )}

        {!tableData.length && isLoading && (
          <div className="skeleton-loader-container">
            <SkeletonTable
              listsToRender={numberOfList}
              numberColumn={column.length || numberOfColumn}
              column={numberOfColumn ? null : column}
            />
            {/* <Loader className="loader-blue" dimension={52} /> */}
          </div>
        )}
      </div>
    </div>
  );
};
