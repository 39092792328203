export const localStorageKey = { LOGIN_KEY: "login-user" };

export const loginToasterMessages = {
  SENT_OTP: "OTP send successfully to mobile number ",
  LOGIN_SUCCESS: "Logged In Successfully",
  PHONE_NUMBER_VALIDATION: "Mobile Number length must be greater than 8",
  INVALID_OTP: "Invalid OTP.",
  ACCEPTED_NUMBER_ONLY: "Only number allowed in OTP input",
};

export const mobileApps = {
  ANDROID_APP: "https://play.google.com/store/apps/details?id=com.liquidity",
  IOS_APP: "https://testflight.apple.com/join/BlDYFgS1",
};
