import React, { useCallback, useEffect, useMemo, useState } from "react";
import styles from "./VerifyOrder.module.sass";
import { ReactModal } from "@components/react-modal";
import clock from "../../../../../../assets/images/clock.svg";
import line from "../../../../../../assets/icon/CheckLine.svg";
import cross from "../../../../../../assets/icon/cross-line.svg";
import classNames from "classnames";
import "@leenguyen/react-flip-clock-countdown/dist/index.css";
import { useWebSocket } from "@hooks/web-socket";
import UseBuySellApi from "../Action/store/buySellApi";
import { Confirmation } from "@storybook";
import { VisibleCancelState, VisibleModifyState } from "@states/Orders";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { OrderPlacedState } from "@views/exchange/components/Orders/store/state";
import UseOrdersApi from "@views/exchange/components/Orders/store/hook";
import { ActiveCurrencyState, SocketTriggeredOnOrderState } from "@states/exchange";
import FlipCountdown from "./counter";

enum ModalType {
  CONFIRM,
  APPROVED,
  DECLINE,
  TIMEUP,
  DEFAULT,
  MODIFIED,
  CANCELLED
}

const VerifyOrder = ({
  isOpen,
  onClose,
  modalType,
  closeBtn,
  setModalType,
  orderResponse,
  setOpenVerfyOrder,
  onSuccess
}: {
  isOpen: boolean;
  onClose: any;
  modalType: ModalType;
  closeBtn: boolean;
  setModalType: any;
  orderResponse?:any
  setOpenVerfyOrder?:any
  onSuccess?:()=> void
}) => {
  const [seconds, setSeconds] = useState(60);
  const [orderSocket, setOrderSocket] = useState<any>({});
  const { CancelOrderVerifcation } = UseBuySellApi();
  const [visibleCancel, setVisibleCancel] = useState(false);
  const setOrderPlaced = useSetRecoilState(OrderPlacedState);
  const { getUsersAssetPortfolio } = UseOrdersApi();
  const activeCurrency = useRecoilValue(ActiveCurrencyState);
  const socketTriggeredOnOrderValue = useRecoilValue(
    SocketTriggeredOnOrderState
  );

  const { id: activeCurrencyId }: any = activeCurrency ?? {};

  useEffect(() => {
    if (
      socketTriggeredOnOrderValue?.type === "MESSAGE" &&
      orderResponse?.orderRequestId ===
        socketTriggeredOnOrderValue?.orderRequestId
    ) {
      setOrderSocket(socketTriggeredOnOrderValue);
    }
  }, [socketTriggeredOnOrderValue]);

  const handleCloseDelete = useCallback(() => {
    setVisibleCancel(false);
  }, []);
  
  useEffect(() => {
    if (seconds === 0) {
      setModalType(ModalType.TIMEUP);
      setTimeout(() => handleCloseModal(), 5000);
    }
  }, [seconds, setModalType]);

  const handleCloseModal = useCallback(() => {
    onClose();
    setSeconds(60);
    setOrderSocket({});
  }, []);

  useEffect(() => {
    const handleModalType = () => {
      if (!orderSocket?.userId || !orderResponse) return;

      if (orderSocket.isSuccess) {
        if (orderResponse.VerificationType === "modifyOrder") {
          setModalType(ModalType.MODIFIED);
        } else if (orderResponse.VerificationType === "cancelOrder") {
          setModalType(ModalType.CANCELLED);
        } else {
          setModalType(ModalType.APPROVED);
          !!onSuccess && onSuccess();
        }
        setTimeout(() => handleCloseModal(), 5000);
      } else {
        setTimeout(() => handleCloseModal(), 5000);
        setModalType(ModalType.DECLINE);
      }
    };

    handleModalType();
    setOrderPlaced((prev: any) => !prev);
    getUsersAssetPortfolio(activeCurrencyId);
  }, [orderSocket?.isSuccess]);


  const handleExtendTime = useCallback(() => {
    setModalType(ModalType.CONFIRM);
  }, [setModalType]);

  const handleCloseVerification = useCallback(() => {
    CancelOrderVerifcation(orderResponse?.requestTime);
    setVisibleCancel(false);
    setSeconds(60);
    setOrderSocket({});
    onClose();
  }, [CancelOrderVerifcation, modalType, onClose, orderResponse?.requestTime]);

  const renderConfirmation = useMemo(() => {
    return (
        <Confirmation
            title={"Cancel Order"}
            visible={visibleCancel}
            description={`Cancelling the order verification is irreversible.`}
            handleModal={handleCloseVerification}
            handleClose={handleCloseDelete}
            boldDescription="Are you sure you want to cancel order?"
            label={"Yes"}
            cancelLabel={"Cancel"}
            type="primary"
        />
    );
}, [handleCloseDelete, handleCloseVerification, visibleCancel]);

  if (modalType === ModalType.DEFAULT) return null;
  return (
    <>
      <ReactModal
        visible={isOpen}
        onClose={() => {modalType===ModalType.CONFIRM ? setVisibleCancel(true) : handleCloseModal() }}
        closeBtn={closeBtn}
        maskClosable={false}
        outerClassName={classNames(styles.verfiyOrderModal)}
      >
        {modalType === ModalType.TIMEUP  && (
          <div className={styles.modalContent}>
            <div className="icon processing-icon">
              <img className={styles.clockImage} src={clock} alt="" />
            </div>
            <div>
              <h2 className={styles.modalContent__heading}>
                Time’s up!
              </h2>
              <p className={styles.modalContent__subHeading}>
                The 60 seconds to approve your order has expired.
              </p>
            </div>
            <div className={styles.modalContent__btnContainer}>
              <button
                className={styles.modalContent__btnClose}
                onClick={handleCloseModal}
              >
                Close
              </button>
              {/* <button
                className={styles.modalContent__btnExtendTime}
                onClick={handleExtendTime}
              >
                Extend time
              </button> */}
            </div>
          </div>
        )}
        {modalType === ModalType.CONFIRM && (
          <div className={styles.modalContent}>
            {/* <div className="icon processing-icon"></div> */}
            <div>
              <FlipCountdown seconds={seconds} setSeconds={setSeconds}/>
            </div>
            <div>
              <h2 className={styles.modalContent__heading}>
                Confirm and verify order
              </h2>
              <p className={styles.modalContent__subHeading}>
                Push notification was sent to your Liquidity mobile application,
                please open the Liquidity app and approve the order.
              </p>
            </div>
            {/* <button
              className={styles.modalContent__btnVerify}
              onClick={onClose}
            >
              Resend Notification
            </button> */}
          </div>
        )}
        {modalType === ModalType.APPROVED && (
          <div className={styles.modalContent}>
            <div className={styles.modalContent__approvedIcon}>
              <img className={styles.icon} src={line} alt="" />
            </div>
            <div>
              <h2 className={styles.modalContent__heading}>
                Order request approved!
              </h2>
              <p className={styles.modalContent__subHeading}>
                Your order request has been successfully approved. You will
                receive a confirmation once the transaction is completed.
              </p>
            </div>
            <button
              className={styles.modalContent__btnClose}
              onClick={handleCloseModal}
            >
              Close
            </button>
          </div>
        )}
        {modalType === ModalType.DECLINE && (
          <div className={styles.modalContent}>
            <div className={classNames(styles.modalContent__DeclineIcon)}>
              <img className={styles.icon} src={cross} alt="" />
            </div>
            <div>
              <h2 className={styles.modalContent__heading}>
                Order request declined
              </h2>
              <p className={styles.modalContent__subHeading}>
                Your order request has been declined. If this was an error,
                please try placing your order again or contact support for
                assistance.
              </p>
            </div>
            <button
              className={styles.modalContent__btnClose}
              onClick={handleCloseModal}
            >
              Close
            </button>
          </div>
        )}
        {modalType === ModalType.MODIFIED && (
          <div className={styles.modalContent}>
             <div className={styles.modalContent__approvedIcon}>
            <img className={styles.icon} src={line} alt="" />
            </div>
            <div>
              <h2 className={styles.modalContent__heading}>
              Order Successfully Modified
              </h2>
              <p className={styles.modalContent__subHeading}>
              Your order request has been successfully modified.
              </p>
            </div>
            <button className={styles.modalContent__btnClose} onClick={handleCloseModal}>
              Close
            </button>
          </div>
        )}
        {modalType === ModalType.CANCELLED && (
          <div className={styles.modalContent}>
             <div className={styles.modalContent__approvedIcon}>
            <img className={styles.icon} src={line} alt="" />
            </div>
            <div>
              <h2 className={styles.modalContent__heading}>
              Order cancelled successfully!
              </h2>
              <p className={styles.modalContent__subHeading}>
              Your order request has been cancelled successfully.
              </p>
            </div>
            <button
              className={styles.modalContent__btnClose}
              onClick={handleCloseModal}
            >
              Close
            </button>
          </div>
        )}
      </ReactModal>
      <ReactModal
        visible={visibleCancel}
        onClose={() => setVisibleCancel(false)}
        maskClosable={false}
        outerClassName={styles.confirmationModalOuter}
      >
        {renderConfirmation}
      </ReactModal>
    </>
  );
};

export default VerifyOrder;
