import "./issuanceMsg.scss";

export const InssuanceMsg = ({title,message}:any) => {
  return (
    <div className="issuance-msg">
      <i className="ri ri-information-line"></i>
      <div>
        <b>{title} : </b> {message}
      </div>
    </div>
  );
};
