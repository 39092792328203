import { useCallback, useEffect, useRef, useState } from "react";
import { useRecoilState } from "recoil";

import { initialQueryPrivateState, PrivatesState } from "states";
import { SkeletonWatchList } from "components";
import { APIS } from "constant";
import { PrivateItem } from "./private-item";

import styles from "../Currency/Currency.module.sass";

import { useNetwork } from "hooks";
import { Image } from "@storybook";
import useDarkMode from "use-dark-mode";
import InfiniteScroll from "react-infinite-scroll-component";
import { getJsonToParams } from "@utils/common";

export const Private = () => {
  const [filters, setFilters] = useRecoilState(initialQueryPrivateState);

  const [privates, setPrivates] = useRecoilState(PrivatesState);
  const darkMode = useDarkMode(false);
  const { get: getPrivatesData, loading: loadingISPrivate } = useNetwork();
  const { get: getPrivates } = useNetwork();

  const refPrivatesInterval = useRef<any>(null);

  // This code is for exchange explorer api polling
  const NewObjectWithNewIdAdded = useCallback(
    (previousArray: any, currentArray: any) => {
      const previousIds = previousArray.map((obj: any) => obj._id);
      const newPrivatesSet = new Set(previousIds);
      if (!newPrivatesSet.has(currentArray[0]?._id)) {
        return currentArray[0];
      } else {
        return "";
      }
    },
    []
  );

  useEffect(() => {
    refPrivatesInterval.current = setInterval(() => {
      getPrivates(`${APIS.Explorers}?include=privates`).then((res: any) => {
        if (res?.message === "ok") {
          const newPrivateAsset = NewObjectWithNewIdAdded(
            privates,
            res?.data?.privates
          );
          if (newPrivateAsset) {
            setPrivates((prev: any) => {
              return [newPrivateAsset, ...prev];
            });
          }
        }
      });
    }, 5000);
    return () => clearInterval(refPrivatesInterval.current);
  }, [privates]);

  const handleFetchMore = useCallback(() => {
    const queryParams = { ...filters, offset: filters.offset + 1 };
    getPrivatesData(`${APIS.Explorers}` + getJsonToParams(queryParams)).then(
      (res: any) => {
        if (res) {
          setPrivates((prev: any) => {
            return [...prev, ...res?.data?.privates];
          });
        }
      }
    );
    setFilters((prev) => ({ ...queryParams }));
  }, [filters]);

  return (
    <>
      {privates?.length === 0 ? (
        <div className={styles.noData}>
          <div className={styles.noDataContent}>
            <Image
              fileName={`images/${
                darkMode.value ? "no_data_dark.svg" : "no_data_light.svg"
              }`}
            />
            <div>No Data Found</div>
          </div>
        </div>
      ) : (
        <InfiniteScroll
          dataLength={privates.length || 0}
          next={handleFetchMore}
          hasMore={
            privates.length >= filters.offset * filters.limit + filters.limit
          }
          loader={""}
          scrollableTarget="assets-scrollableDiv"
        >
          {privates?.map((data, index) => {
            return (
              <PrivateItem lastTradedPrice={0} amount={0} key={data?.id} index={index} {...(data ?? {})} />
            );
          })}
        </InfiniteScroll>
      )}
      {loadingISPrivate && <SkeletonWatchList listsToRender={4} />}
    </>
  );
};
