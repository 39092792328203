import { FC, useEffect, useRef } from "react";
import { useRecoilValue } from "recoil";
import useDarkMode from "use-dark-mode";

import { ActiveCurrencyState } from "states";
import { useCurrency } from "hooks";

import styles from "./candle-chart.module.sass";

const chartWidth = "100%";

interface Props {
  /**
   * Height of chart -- default: 220.
   */
  width?: number;
  chartPerDay: IPerDay[];
  isLoading: boolean;
}

interface IPerDay {
  open: number;
  low: number;
  high: number;
  value: number;
  time: any;
  volume: number;
}
export const CandleChart: FC<Props> = ({ chartPerDay, isLoading }) => {
  const activeCurrency = useRecoilValue(ActiveCurrencyState);
  const darkMode = useDarkMode();
  const chartRef = useRef<any>(null);
  const { formatCurrencyNumber } = useCurrency();
  const { symbol } = activeCurrency;


  const chartSortedData = () => {
    const data = chartPerDay
    ?.map(({ time, open, high, low, value: close, volume }: IPerDay) => {
      const newOpen = formatCurrencyNumber(open, 2).split(",").join("");
      const newHigh = formatCurrencyNumber(high, 2).split(",").join("");
      const newLow = formatCurrencyNumber(low, 2).split(",").join("");
      const newClose = formatCurrencyNumber(close, 2).split(",").join("");

      return [
        time,
        Number(newOpen),
        Number(newHigh),
        Number(newLow),
        Number(newClose),
        volume,
      ];
    });

    return data;
  }

  useEffect(() => {
    const data = chartSortedData();
    let chart = (window as any).Highcharts.stockChart("container", {
      plotOptions: {
        candlestick: {
          color: "#F55353",
          lineColor: "#F55353",
          upLineColor: "#33B87A",
          upColor: "#33B87A",
        },
      },
      credits: {
        enabled: false,
      },
      rangeSelector: {
        enabled: false,
      },
      loading: {
        style: {
          backgroundColor: "transparent",
        },
      },
      navigator: {
        series: {
          color: "#666",
          animation: false,
          fillOpacity: 0.12,
          lineColor: "#666",
          label: "",
        },
        maskFill: "rgba(255, 255, 255, 0.06)",
        outlineColor: "#282A2E",
        outlineWidth: 1,
        handles: {
          backgroundColor: "#ADB0B8",
          borderColor: "#999999",
          enabled: true,
          height: 32,
          lineWidth: 0,
          width: 8,
        },
        xAxis: {
          gridLineColor: "#36383D",
          gridLineWidth: 0.8,
          tickPixelInterval: 100,
          labels: {
            style: { color: darkMode.value ? "white" : "black" },
          },
        },
      },
      series: [
        {
          type: "candlestick",
          name: symbol,
          data: data,
        },
      ],
      time: {
        useUTC: false,
      },
      chart: {
        backgroundColor: "transparent",
        zoomBySingleTouch: true,
        zoomType: "x",
        animation: true,
        zooming: {
          mouseWheel: {
            enabled: false,
          }
        },
      },
      scrollbar: {
        enabled: false,
      },
      xAxis: [
        {
          lineWidth: 1,
          lineColor: "#36383D",
          tickWidth: 1,
          tickColor: "#36383D",
        },
      ],
      yAxis: [{ gridLineColor: "#36383D", gridLineWidth: 0.4 }],
      exporting: {
        enabled: false,
      },
      tooltip: {
        changeDecimals: 4,
      },
    });

    chartRef.current = chart;
    if (isLoading) {
      chart.showLoading();
    } else {
      chart.hideLoading();
    }
    
  }, [isLoading, symbol, darkMode.value]);

  
  useEffect(() => {
    const chart = chartRef?.current;

    chart?.series[0]?.setData(chartSortedData());
  }, [chartPerDay]);

  return (
    <div style={{ flex: 1 }}>
      <div
        style={{
          backgroundColor: "transparent",
          width: chartWidth,
        }}>
        <div className={styles.tab_candle_chart} id="container"></div>
      </div>
    </div>
  );
};
