import { FC } from "react";
import { useRecoilValue } from "recoil";
import useDarkMode from "use-dark-mode";

import { ActiveCurrencyState } from "states";

import styles from "./AssetDetail.module.sass";
import "./asset-details.scss"

declare global {
  namespace JSX {
    interface IntrinsicElements {
      "private-assets": any;
    }
  }
}

interface IAssetDetail {
  isPrivate: boolean;
}

export const AssetDetail: FC<IAssetDetail> = ({ isPrivate }) => {
  //globle state
  const activeCurrency = useRecoilValue(ActiveCurrencyState);
  // hook
  const darkMode = useDarkMode();

  return (
    <div
      className={styles.AssetDetail}
      dangerouslySetInnerHTML={{
        __html: `<private-assets
          theme=${darkMode.value ? "dark" : "light"}
          edit="false"
          asset_id=${activeCurrency.address ?? "87283611"}
          id=${activeCurrency.id} 
          asset_name=${activeCurrency.name}
          asset_symbol=${activeCurrency.symbol ?? ""}
          private=${isPrivate.toString()}
          offer_type="${activeCurrency.offeringType ?? "---"}"
          asset_wrapper="#exchange--wrapper"
        />`,
      }}
    />
  );
};
