import { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import cn from "classnames";
import useDarkMode from "use-dark-mode";

import Theme from "components/Theme";
import { useRecoilState, useRecoilValue } from "recoil";
import { useCookie, useNetwork } from "hooks";
import { Image } from "@storybook";
import { ROUTES, SIDEBAR_TABS } from "routes";
import {
  CollapseSideBarState,
  linkedDeviceState,
  showEmailNotificationState,
} from "states";
import { Tooltip as ReactTooltip } from "react-tooltip";
import { useMediaQuery } from "react-responsive";
import { APIS } from "constant";

import styles from "./sidebar.module.sass";
import { Json } from "types/common";

type SubRoute = {
  ROUTE: string;
};

type IsMainTabActive = (subRoutes: SubRoute[]) => boolean;

export const Sidebar = () => {
  // hooks
  const location = useLocation();
  const navigate = useNavigate();
  const { LOGIN } = ROUTES;
  const { deleteCookie } = useCookie();
  const [isCollapsed, setIsCollapsed] = useRecoilState(CollapseSideBarState);
  const emailNotificationValue = useRecoilValue(showEmailNotificationState);
  const darkMode = useDarkMode();
  const [collapseRoute, setCollapseRoute] = useState<any>(new Set(["mintedAsset"]));
  const isMobile = useMediaQuery({ query: "(max-width: 768px)" });

  const { get: getCookieData } = useCookie();
  const devicelist = useRecoilValue(linkedDeviceState);
  const { patch: removeLinkedDevice } = useNetwork();

  const heightForEmailNotification =
    emailNotificationValue == true ? "calc( 100vh - 44px )" : "100vh";

  const handleChangeTab = useCallback((tabName: string, idKey?: string) => {
      if (idKey && document.getElementById(idKey)) {
        document.getElementById(idKey)?.click();
        navigate(`/${tabName}`);
      } else {
        navigate(`/${tabName}`);
      }
    },
    [navigate]
  );

  const handleClick = useCallback(() => {
    navigate("/exchange");
  }, [navigate]);

  const handleToggle = useCallback((key: string) => {
    if (collapseRoute.has(key)) {
      collapseRoute.delete(key);
    } else {
      collapseRoute.add(key);
    }

    setCollapseRoute(new Set([...collapseRoute]));
  }, [collapseRoute])

  const logoutAction = () => {
    const registrations = JSON.parse(
      localStorage.exchageAppRegistration || "[]"
    );
    localStorage.clear();
    deleteCookie("userDetails");
    // localStorage.setItem(
    //   "exchageAppRegistration",
    //   JSON.stringify(registrations)
    // );
    deleteCookie("userDetails");
    window.location.href = LOGIN;
  };
  const onLogout = useCallback(() => {
    const userDetails = getCookieData("userDetails");

    const currentDeviceDetail = devicelist.find(
      (dev: any) => dev?.deviceToken === userDetails?.token
    );
    const payload = {
      ids: [currentDeviceDetail?._id],
      origin: "web",
    };
    removeLinkedDevice(
      `${APIS.UsersDeviceLogin}/${currentDeviceDetail?.userId}`,
      payload,
      { apiResponse: true }
    ).then((resp) => {
      logoutAction();
    }).catch((err) => {
      logoutAction();
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [devicelist]);
  const handleToggleCollapse = () => {
    setIsCollapsed((prev) => !prev);
  };

  useEffect(() => {
    if (isMobile) {
      setIsCollapsed(true);
    } else {
      setIsCollapsed(false);
    }
  }, [isMobile, setIsCollapsed]);

  const handleDarkMode = useCallback(() => {
    if (!darkMode.value){ 
      darkMode.toggle();
      const id = document.getElementById("themeChangeFromExchange")
      if(id) id.click();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [darkMode]);

  const handleLightMode = useCallback(() => {
    if (darkMode.value){ 
      darkMode.toggle();
      const id = document.getElementById("themeChangeFromExchange")
      if(id) id.click();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [darkMode]);

  const handleLogoClick = () => {
    const logoClickDestination = "/exchange";
    navigate(logoClickDestination);
  };

  const sidebarMap = useMemo(() => {
    const lists = SIDEBAR_TABS;
    let listingData = lists.tabs;


    const isMainTabActive: IsMainTabActive = (subRoutes: SubRoute[]) => {
      return subRoutes.some(({ ROUTE }) => location.pathname.startsWith(`/${ROUTE}`));
    };

    return (
      <>
        {listingData.map(({ ROUTE, TITLE, ICON, SUB_ROUTE, KEY, ID }, index) => (
          <div key={TITLE}>
            <div
              style={{ display: SUB_ROUTE && isCollapsed ? "none" : "" }}
              className={cn({
                [styles.sidebarTitle]: !isCollapsed,
                [styles.collapseTitle]: isCollapsed,
              })}
              onClick={() =>
                SUB_ROUTE ? handleToggle(KEY) : handleChangeTab(ROUTE, ID)
              }
            >
              <div className={styles.sidebarIcon}>
                <i className={cn(ICON, {
                  [styles.activeTab]: location.pathname.startsWith(`/${ROUTE}`) || (SUB_ROUTE && isMainTabActive(SUB_ROUTE)),
                })} id={ICON} data-tooltip-id={ICON + TITLE} />
                {isCollapsed && <ReactTooltip className="theme-tooltip" id={ICON + TITLE} place="right" content={TITLE}
                                              style={{ zIndex: 101 }} />}

              </div>
              {!isCollapsed && <div
                className={cn({
                  [styles.sidebar_text]: true,
                  [styles.activeTab]: location.pathname.startsWith(`/${ROUTE}`) || (SUB_ROUTE && isMainTabActive(SUB_ROUTE)),
                })}
              >
                {TITLE}
              </div>}
              {SUB_ROUTE && !isCollapsed && (
                <i
                  style={{ fontSize: "24px" }}
                  className={`ri ${collapseRoute.has(KEY)
                    ? "ri-arrow-up-s-line"
                    : "ri-arrow-down-s-line"
                  } `}
                  id={ICON}
                />
              )}
            </div>
            {SUB_ROUTE &&
              (isCollapsed || collapseRoute.has(KEY)) &&
              SUB_ROUTE?.map(({ ROUTE, TITLE, ICON, ID: idValue }: Json, index) => {
                return (
                  <div
                    key={TITLE}
                    className={cn({
                      [styles.subPadding]: !isCollapsed,
                      [styles.sidebarTitle]: !isCollapsed,
                      [styles.collapseTitle]: isCollapsed,
                    })}
                    onClick={() => handleChangeTab(ROUTE, idValue)}
                  >
                    <div className={styles.sidebarIcon}>
                    {isCollapsed && <i className={`${ICON}`} id={ICON} data-tooltip-id={ICON + TITLE} />}
                      {!isCollapsed && <div className={cn({
                        [styles.subLine]: location.pathname === (
                          `/${ROUTE}`),
                      })} />}
                      {isCollapsed &&
                        <ReactTooltip className="theme-tooltip" id={ICON + TITLE} place="right" content={TITLE}
                                      style={{ zIndex: 101 }} />}
                    </div>
                    {!isCollapsed && <div
                      className={cn({
                        [styles.sidebar_text]: true,
                        [styles.activeTab]: location.pathname === (
                          `/${ROUTE}`
                        ),
                      })}
                      onClick={() => handleChangeTab(ROUTE, idValue)}
                    >
                      {TITLE}
                    </div>}
                  </div>
                );
              })}
          </div>
        ))}
      </>
    );
  }, [
    isCollapsed,
    location.pathname,
    collapseRoute,
    handleToggle,
    handleChangeTab,
  ]);

  return (
    <div
      className={cn({
        [styles.collapse_inner]: isCollapsed,
        [styles.sidebarContainer]: !isCollapsed,
      })}
      style={{ height: heightForEmailNotification }}
    >
      <div
        className={styles.sidebar__collapse_button}
        onClick={handleToggleCollapse}
      >
        <i
          className={`ri-${isCollapsed ? "arrow-right-s-line" : "arrow-left-s-line"
          }`}
        ></i>
      </div>
      <div className={styles.sidebarHead}>
        <div className={styles.brandLogo} onClick={handleLogoClick}>
          <Image
            className={`${styles.brand_logo_img} ${!isCollapsed ? styles.visible : styles.hidden}`}
            fileName={`images/Liquidity_Blue_Logo.png`}
          />
          <Image
            className={`${styles.logoSmall} ${isCollapsed ? styles.visible : styles.hidden}`}
            fileName={`images/LiquidSmall.svg`}
          />
        </div>

      </div>
      <div className={styles.sidebarBody}>
        <div className={styles.sidebarTabsContainer}>
          {sidebarMap}
        </div>

        <div className={styles.sidebarFooterTabsContainer}>
          <div
            className={cn({
              [styles.sidebarTitle]: !isCollapsed,
              [styles.collapseTitle]: isCollapsed,
            })}
            onClick={handleClick}
          >
            <div className={styles.sidebarIcon}>
              <i className="ri-arrow-left-s-line" data-tooltip-id={"Back"} />
              {isCollapsed && <ReactTooltip className="theme-tooltip" id={"Back"} place="right" content={"Back"}
                                            style={{ zIndex: 101 }} />}
            </div>
            {!isCollapsed ? <p className={styles.sidebar_text}>Back</p> : ""}
          </div>
          {/* <div
          className={cn({
            [styles.sidebarTitle]: !isCollapsed,
            [styles.collapseTitle]: isCollapsed,
          })}
        >
          <div className={styles.sidebarIcon}>
            <i className="ri-notification-3-line" />
          </div>
          {!isCollapsed ? (
            <p className={styles.sidebar_text}>Notification</p>
          ) : (
            ""
          )}
        </div> */}
          <div
            className={cn({
              [styles.sidebarTitle]: !isCollapsed,
              [styles.collapseTitle]: isCollapsed,
            })}
            onClick={onLogout}
          >
            <div className={styles.sidebarIcon} onClick={onLogout}>
              <i className="ri-logout-box-r-line" data-tooltip-id={"Logout"} />
              {isCollapsed && <ReactTooltip className="theme-tooltip" id={"Logout"} place="right" content={"Logout"}
                                            style={{ zIndex: 101 }} />}
            </div>
            {!isCollapsed ? <p className={styles.sidebar_text}>Logout</p> : ""}
          </div>
          {!isCollapsed ? (
            <div className={styles.sidebarTitle}>
              <div className={styles.sidebar_btn_box}>
                <button
                  className={styles.sidebar_btn_light}
                  onClick={handleLightMode}
                >
                  <span className={styles.sidebarBtnIconLight}>
                    <i className="ri-sun-fill" />
                  </span>
                  <p className={styles.btn_textlight}>Light</p>
                </button>
                <button
                  className={styles.sidebar_btn_dark}
                  onClick={handleDarkMode}
                >
                  <span className={styles.sidebarBtnIcon}>
                    <i className="ri-moon-line" />
                  </span>
                  <p className={styles.btn_text}>Dark</p>
                </button>
              </div>
            </div>
          ) : (
            <div
              className={cn({
                [styles.sidebarTitle]: !isCollapsed,
                [styles.collapseTitle]: isCollapsed,
              })}
            >
              <span className={styles.sidebarIcon} data-tooltip-id={"theme"}>
                <Theme className={styles.theme} icon small={undefined} size="20" />
                <ReactTooltip className="theme-tooltip" id={"theme"} place="right" content={"Change Theme"}
                              style={{ zIndex: 101 }} />
              </span>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
