import { FC } from "react";
import NewModal from "@components/new-modal/new-modal";
import { getEnv } from "@utils/common";
import { REACT_APP_API_HOST } from "../../envs";
import { useRecoilValue } from "recoil";
import { userPersonalDetails } from "@states/user";
import "./session-detail-modal.scss"

interface Props {
  handleClose: () => void;
  token: string;
}

export const SessionDetailsModal: FC<Props> = ({
  handleClose,
  token,
}) => {
  const personalData = useRecoilValue(userPersonalDetails);


  const environmentHost = () => {
    if (getEnv(REACT_APP_API_HOST) === "prod") return "prod";
    return "beta";
  };

  return (
    <NewModal
      isOpen={true}
      modalName="Session Modal"
      className="session-detail-modal"
      showCloseBtn={true}
      isStopOutsideClick={true}
      closeOnEscBtn={true}
      closeModal={handleClose}
    >
      <iframe
        srcDoc={`<html>
    <head>
        <script src="https://code.highcharts.com/highcharts.js"></script>
        <script src="https://code.highcharts.com/highcharts-more.js"></script>
        <script src="https://cdnjs.stage.satschel.com/v2/sessions/web-component.js"></script>
    </head>
    <body>
        <div class="container">
            <session-details session_id=${personalData?.sessionId} token=${token} comments="true" env=${environmentHost()} type="sessions" compliance_data="false" url="/complex-session-details" host_type="v2" action_disable=${true} only_activity=${true} />
        </div>
    </body>
</html>`}
      ></iframe>

    </NewModal>
  )
};
