import React, { useMemo } from "react";
import classNames from "classnames";
import useDarkMode from "use-dark-mode";
import WalletLight from "../../../../../../assets/images/WalletLight.svg";
import WalletDark from "../../../../../../assets/images/WalletDark.svg";

import styles from "./MpcIncomplete.module.sass";
import { userDetailsState } from "@states/user";
import { useRecoilValue } from "recoil";
import { FortressAccountInfo } from "@views/MyWallet/store/state";

const MpcIncomplete = ({ handleOpenMpcSetup,showZeroBalance=true }: any) => {
  const darkmode = useDarkMode(false);
  const userDetails = useRecoilValue(userDetailsState);
  const availableBalance = useRecoilValue<any>(FortressAccountInfo);

  const renderMpcWalletStatus = useMemo(() => {
    return (
      <div className={styles.renderKycStatus}>
        <div className={styles.renderKycStatus__usersImg}>
          <img src={darkmode.value ? WalletDark : WalletLight} alt="" />
        </div>
        <div className={styles.renderKycStatus__Texts}>
          <div className={styles.renderKycStatus__Texts__heading}>
          Setup Incomplete
          </div>
          <div className={styles.renderKycStatus__Texts__subHeading}>
          You haven't completed the setup. Please finish it to make trades and transactions.
          </div>
        </div>
        <div className={styles.renderKycStatus__Status}>
          <div className={styles.renderKycStatus__Status__upper}>
            <div
              className={classNames(
                styles.renderKycStatus__Status__Kyc,
                styles.renderKycStatus__Status__border
              )}
            >
              {userDetails?.data?.isMPCWallet ? (
                <i
                  className={classNames(
                    `ri-checkbox-circle-fill`,
                    styles.renderKycStatus__Status__checkboxIcon
                  )}
                />
              ) : (
                <span
                  className={classNames(
                    styles.renderKycStatus__Status__circle
                    // {
                    //   [styles.renderKycStatus__Status__red]:
                    //     mpcWallet === "rejected",
                    // },
                    // {
                    //   [styles.renderKycStatus__Status__yellow]:
                    //     mpcWallet === "processing",
                    // }
                  )}
                ></span>
              )}
              Wallet
            </div>
            {showZeroBalance &&  <div
              className={classNames(
                styles.renderKycStatus__Status__aml,
                styles.renderKycStatus__Status__border
              )}
            >
              {availableBalance && availableBalance?.balance !== 0 ? (
                <i
                  className={classNames(
                    `ri-checkbox-circle-fill`,
                    styles.renderKycStatus__Status__checkboxIcon
                  )}
                />
              ) : (
                <span
                  className={classNames(
                    styles.renderKycStatus__Status__circle
                    // {
                    //   [styles.renderKycStatus__Status__red]:
                    //     zeroBalance === "rejected",
                    // },
                    // {
                    //   [styles.renderKycStatus__Status__yellow]:
                    //     zeroBalance === "processing",
                    // }
                  )}
                ></span>
              )}
              Zero balance
            </div>}
           
          </div>
        </div>
        <button
          className={styles.renderKycStatus__btn}
          onClick={handleOpenMpcSetup}
        >
          {!userDetails?.data?.isMPCWallet ? "Complete Setup" : "Deposit"}
        </button>
      </div>
    );
  }, []);
  return <div>{renderMpcWalletStatus}</div>;
};

export default MpcIncomplete;
