import useDarkMode from "use-dark-mode";
import cn from "classnames";

import styles from "./mobile-view.module.sass";
import { Image } from "@storybook";
// import { mobileApps } from "constant";

const Mobileview = () => {
  const darkMode = useDarkMode(false);
  // const { ANDROID_APP, IOS_APP } = mobileApps;

  return (
    <div className={cn(darkMode.value ? styles.page : "")}>
      <div className={styles.main}>
        <Image fileName={"images/LiquidSmall.svg"} width="64px" />
        <div className={styles.title}>
          Mobile App Coming Soon!
          {/* Liquidity mobile app is available for both <br/> iOS and Android platform */}
        </div>

        <div className={styles.text}>
          Liquidity.io is currently available only on desktop. In the meantime,
          please access the platform from your desktop for the best experience.
          {/* Liquidity mobile app is available for both <br/> iOS and Android platform */}
        </div>
        {/* <div className={styles.app_buttons}>
          <a href={IOS_APP}>
            <Image fileName={"images/appstore.svg"} />
          </a>
          <a href={ANDROID_APP}>
            <Image fileName={"images/android-appstore.svg"} />
          </a>
        </div> */}
      </div>
      {/* <div className={styles.footer}>
        <Image fileName={"images/Originalwordmark.svg"} />
      </div> */}
    </div>
  );
};

export default Mobileview;
