import React, { useCallback, useEffect, useMemo } from "react";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { useCurrency } from "@hooks/currency";
import { convertToDays, getDateWithFormatTime } from "@utils/getTime";
import { maintenanceState, userPersonalDetails } from "@states/user";
import { useNotification } from "@hooks/notification";
import { ORDER_TYPE } from "../constants";
import { ActiveCurrencyState, IOrderState } from "states";
import {
  SelectedOrderState,
  VisibleCancelState,
  VisibleModifyState,
} from "@states/Orders";
import classNames from "classnames";
import {
  capitalizeFirstLetterOnly,
} from "@utils/common";
import { Tooltip as ReactTooltip } from "react-tooltip";

import styles from "./OrderTable.module.sass";
import "./order-table.scss";
import { assestType, gov_guaranteed_loan, LOAN_ASSETS, ORDERBOOK_SERIES } from "@views/exchange/constants";
import { Json } from "../../../../../types/common";
import { getCategoryLabel } from "@utils/getString";
import { usePrevious } from "@hooks/common";
import { CountdownTimer } from "@components/counter";
import { CircularProgressbarWithChildren } from "react-circular-progressbar";
import { AssetPortfolioState } from "@views/exchange/components/Orders/store/state";
import { formatNumberWithCommas } from "@utils/format-number";

type ITableData = {
  activeTab: string;
  ordersData:Json
};

const TableData = ({ activeTab, ordersData}: ITableData) => {
  const personalData = useRecoilValue(userPersonalDetails);
  const [visibleModify, setVisibleModify] = useRecoilState(VisibleModifyState);
  const prevVisibleModify = usePrevious(visibleModify);
  const [visibleCancel, setVisibleCancel] = useRecoilState(VisibleCancelState);
  const prevVisibleCancel = usePrevious(visibleCancel);
  const setSelectedOrderState = useSetRecoilState(SelectedOrderState);
  const activeCurrency = useRecoilValue(ActiveCurrencyState);
  const maintenance = useRecoilValue(maintenanceState);
  const assetPortfolio = useRecoilValue(AssetPortfolioState);

  const { formatCurrencyWithBillion, amountToBondPoints } = useCurrency();
  const { errorNotification } = useNotification();

  useEffect(() => {
    if ((prevVisibleModify && !visibleModify) || (prevVisibleCancel && !visibleCancel)) {
      setSelectedOrderState({} as IOrderState)
    }
  }, [visibleModify, visibleCancel]);

  const handleCancelOrder = useCallback(
    (id: any) => {
      if (personalData.isTradeEnabled === false) {
        errorNotification("Trading is not allowed");
      } else {
        setVisibleCancel(true);
        const orderData = ordersData["PENDING"].find(
          (item: any) => item.id === id
        );
        setSelectedOrderState(orderData);
      }
    },
    [errorNotification, ordersData, personalData.isTradeEnabled, setSelectedOrderState, setVisibleCancel]
  );

  const handleModifyOrder = useCallback(
    (id: any) => {
      if (personalData.isTradeEnabled === false) {
        errorNotification("Trading is not allowed");
      } else {
        setVisibleModify(true);
        const orderData = ordersData["PENDING"].find(
          (item: any) => item.id === id
        );
        setSelectedOrderState(orderData);
      }
    },
    [errorNotification, ordersData, personalData.isTradeEnabled, setSelectedOrderState, setVisibleModify]
  );
  const getSymbol = (symbol:any)=>{
    const arr=symbol?.split("");
    if(arr?.includes(".")){
      return (symbol)?.split(".")[1]
    }else{
      return symbol;
    }
  }

  const isOrderbook = activeCurrency?.sbaType === ORDERBOOK_SERIES;
  const isGovGuarunteed = activeCurrency?.loanType === gov_guaranteed_loan;
  
  const lockInTime = useCallback((orderdate: any) => {
    const durationObj = convertToDays(activeCurrency?.lockupPeriod, orderdate)
    return durationObj?.futureDate;
  }, [activeCurrency?.lockupPeriod])

  const renderLockInTime = useCallback(({ days, hours, minutes, seconds, completed }: Json) => {
    if (completed) {
      // update state after timer is over
    }
    const time = completed ? 'No Lockup' : `${!!days ? days + "D :" : ""} ${
      (hours < 10 ? "0" : "") + hours
    }H : ${(minutes < 10 ? "0" : "") + minutes}M : ${(seconds < 10 ? "0" : "") + seconds}S`;
    return (
      <div className="lockInCount">
        <CircularProgressbarWithChildren
          value={100} maxValue={100} className="countCircle" strokeWidth={6} counterClockwise>
        </CircularProgressbarWithChildren>
        <div>
          <span className={styles.timeColor}>{time}</span>
        </div>
      </div>
    );
  }, [activeCurrency?.id]);
  
  const orderTable = useMemo(
    () =>
      (ordersData[activeTab] || [])?.map((asset: any, index: number) => (
        <tr className={`${styles.OrderTableRow} `} key={index}>
          <td className={classNames(styles.symbol, styles.openOrderHeading)}>
            {getSymbol(asset?.symbol)}
          </td>
          <td className={classNames(styles.orderType, styles.openOrderHeading)}>
            <div className={styles.OrderTypeContainer}>
              <div
                className={classNames(styles.dotColor, {
                  [styles.buy]: asset?.type === "BUY",
                  [styles.sell]: asset?.type === "SELL",
                })}
              ></div>{" "}
              {capitalizeFirstLetterOnly(asset?.type)}
            </div>
          </td>

          <td className={classNames(styles.assetType, styles.openOrderHeading)}>
            {getCategoryLabel(asset?.category)}
          </td>
          <td className={classNames(styles.assetQty, styles.openOrderHeading)}>
            {formatNumberWithCommas(asset?.quantity)}
          </td>
          {!LOAN_ASSETS[activeCurrency?.type] && (
            <td className={styles.openOrderHeading}>
              {formatNumberWithCommas(formatCurrencyWithBillion(asset?.price, 2))}
            </td>
          )}

          {ordersData[ORDER_TYPE.EXECUTED] &&
            Number(activeCurrency?.lockupPeriod?.duration) > 0 && (
              <td className={styles.openOrderHeading}>
                {asset?.type === "BUY" && asset?.tradeType === "primary" ? <CountdownTimer
                  dateTime={lockInTime(asset?.orderdate)}
                  renderer={renderLockInTime}
                /> : "--"}
              </td>
            )}

          <td className={styles.openOrderHeading}>
            {asset?.orderdate ? getDateWithFormatTime(asset?.orderdate) : "-"}
          </td>

          <td className={styles.openOrderHeading}>
            {LOAN_ASSETS[activeCurrency?.type] &&
              Number(
                amountToBondPoints(
                  isOrderbook
                    ? isGovGuarunteed
                      ? asset?.government_guaranteed_loan_balance
                      : asset?.current_loan_balance
                    : activeCurrency?.currentLoanBalance,
                  asset?.totalamount
                )
              ).toFixed(6)}{" "}
            {LOAN_ASSETS[activeCurrency?.type]
              ? `(${formatCurrencyWithBillion(asset?.totalamount, 2)})`
              : formatCurrencyWithBillion(asset?.totalamount, 2)}
          </td>
          {!ordersData[ORDER_TYPE.CANCELLED] && (
            <td
              className={classNames(
                styles.orderActions,
                styles.openOrderHeading
              )}
            >
              {" "}
              <div className={styles.col}>
                <div className={styles.actions}>
                  {!LOAN_ASSETS[activeCurrency?.type] && (
                    <>
                      <div
                        className={`${styles.link}  ${
                          ordersData[ORDER_TYPE.PENDING] ||
                          asset?.polygonData?.transaction?.hash === ""
                            ? styles.disable
                            : styles.cancel
                        }`}
                        onClick={(e) => {
                          if (
                            !ordersData[ORDER_TYPE.PENDING] &&
                            asset?.polygonData?.transaction?.hash !== ""
                          )
                            window.open(
                              `${asset?.polygonData?.transaction?.url}`,
                              "_blank"
                            );
                        }}
                      >
                        Transaction hash
                      </div>
                      <div
                        className={`${styles.link}  ${
                          !asset?.polygonData?.approval?.hash
                            ? styles.disable
                            : styles.cancel
                        }`}
                        onClick={() => {
                          if (asset?.polygonData?.approval?.hash)
                            window.open(
                              `${asset?.polygonData?.approval?.url}`,
                              "_blank"
                            );
                        }}
                      >
                        Approve hash
                      </div>
                    </>
                  )}
                  {ordersData[ORDER_TYPE.PENDING] && (
                    <>
                      <button
                        id={`cancel-tooltip-${index}`}
                        disabled={maintenance?.isTreadingMaintenance}
                        className={classNames(
                          styles.link,
                          maintenance?.isTreadingMaintenance
                            ? [styles.disable]
                            : [styles.cancel]
                        )}
                        onClick={() => handleCancelOrder(asset?.id)}
                      >
                        Cancel
                      </button>
                      <ReactTooltip
                        anchorId={`cancel-tooltip-${index}`}
                        content={
                          maintenance?.isTreadingMaintenance
                            ? "This order cannot be modified as the trading system is temporarily unavailable. Please try again later."
                            : ""
                        }
                        place="top"
                        className={classNames(
                          "theme-tooltip",
                          styles.tradeBlockTooltip
                        )}
                      />

                      <button
                        id={`modify-tooltip-${index}`}
                        disabled={maintenance?.isTreadingMaintenance}
                        className={classNames(
                          styles.link,
                          maintenance?.isTreadingMaintenance
                            ? [styles.disable]
                            : [styles.modify]
                        )}
                        onClick={() => handleModifyOrder(asset?.id)}
                      >
                        Modify
                      </button>
                      <ReactTooltip
                        anchorId={`modify-tooltip-${index}`}
                        content={
                          maintenance?.isTreadingMaintenance
                            ? "This order cannot be modified as the trading system is temporarily unavailable. Please try again later."
                            : ""
                        }
                        place="top"
                        className={classNames(
                          "theme-tooltip",
                          styles.tradeBlockTooltip
                        )}
                      />
                    </>
                  )}
                </div>
              </div>
            </td>
          )}
        </tr>
      )),
    [
      activeCurrency?.currentLoanBalance,
      activeCurrency?.type,
      activeTab,
      amountToBondPoints,
      formatCurrencyWithBillion,
      handleCancelOrder,
      handleModifyOrder,
      isGovGuarunteed,
      isOrderbook,
      maintenance?.isTreadingMaintenance,
      ordersData,
    ]
  );

    return (
      <table>
        <thead className={styles.orderTable}>
          <tr>
            <th className={classNames(styles.symbol, styles.openOrderHeading)}>
              Symbol
            </th>
            <th
              className={classNames(styles.orderType, styles.openOrderHeading)}
            >
              Order type
            </th>
            <th
              className={classNames(styles.assetType, styles.openOrderHeading)}
            >
              Asset type
            </th>
            <th
              className={classNames(styles.assetQty, styles.openOrderHeading)}
            >
              Qty{" "}
            </th>
            {!LOAN_ASSETS[activeCurrency?.type] && (
              <th className={styles.openOrderHeading}>Price (USD)</th>
            )}

            {ordersData[ORDER_TYPE.EXECUTED] &&
              Number(activeCurrency?.lockupPeriod?.duration) > 0 &&
               (
                <th
                  className={classNames(
                    styles.assetQty,
                    styles.openOrderHeading
                  )}
                >
                  Lockup Period
                </th>
              )}

            <th className={styles.openOrderHeading}>Time</th>

            <th className={styles.openOrderHeading}>
              {LOAN_ASSETS[activeCurrency?.type]
                ? "Price in bond points (USD)"
                : "Total (USD)"}
            </th>

            {!ordersData[ORDER_TYPE.CANCELLED] && (
              <>
                {!(
                  LOAN_ASSETS[activeCurrency?.type] &&
                  ordersData[ORDER_TYPE.EXECUTED]
                ) && (
                  <th
                    className={classNames(
                      styles.orderActions,
                      styles.openOrderHeading
                    )}
                  >
                    Actions
                  </th>
                )}
              </>
            )}
          </tr>
        </thead>
        <tbody>{ordersData[activeTab]?.length > 0 && orderTable}</tbody>
      </table>
    );
};

export default TableData;
