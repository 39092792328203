import { CLIENT_ENVIRONMENT } from "@routes/constants";
import { ENVIRONMENT } from "envs";
import { logEvent } from "firebase/analytics";
import {analytics} from "libs";


export const trackEventGA = (
  eventName: string,
  properties?: Record<string, any>,
  email?: string
) => {
  if (
    ENVIRONMENT === CLIENT_ENVIRONMENT.prod &&
    (!email || !email?.includes("satschel.com"))
  ) {
    logEvent(analytics, eventName, properties );
  }
};
